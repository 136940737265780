import {createListenerMiddleware, isAnyOf} from "@reduxjs/toolkit";
import {loadUserData, userLogout} from "./thunks";

const failSafeLogoutMiddleware = createListenerMiddleware();

failSafeLogoutMiddleware.startListening({
  matcher: isAnyOf(loadUserData.rejected),
  effect: (_, {dispatch}) => {
    dispatch(userLogout());
  },
});

export default failSafeLogoutMiddleware.middleware;
