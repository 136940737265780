import {
  BrowserRouter,
  Outlet, Route, Routes, useNavigate,
} from "react-router-dom";
import {authRoutes} from "./auth";
import {dashboardRoutes} from "./dashboard";
import {publicRoutes} from "./public";
import {React, Suspense, useContext, useEffect} from "react";
import {DashboardLayout} from "../components/layout/DashboardLayout";
import {AuthContext, AuthProvider} from "../services/authContext";
import {AuthLayout} from "../components/layout/AuthLayout";
import Page from "../pages/Page";
import {useSelector} from "react-redux";
import {vendorRoutes} from "./vendors";
import {masterModuleRoutes} from "./masterModule";
import {VendorViewLayout} from "../components/layout/VendorViewLayout";
import {Loader} from "../components/common/Loader";
import {MasterViewLayout} from "../components/layout/MasterViewLayout";
import {UserViewLayout} from "../components/layout/UserViewLayout";
import {userModuleRoutes} from "./userModule";

/**
 * The main router wraps all other route groups
 * @return {React.ReactNode}
 */
export default function Router() {
  return (
    <BrowserRouter>
      <Suspense fallback={Page}>
        <Routes>
          <Route element={<AuthProvider />}>
            <Route>
              {publicRoutes()}
            </Route>

            <Route element={<PreventAuth />}>
              {authRoutes()}
            </Route>

            <Route element={<RequireAuth />}>
              {dashboardRoutes()}

              <Route element={<VendorViewLayout />}>
                {vendorRoutes()}
              </Route>

              <Route element={<MasterViewLayout />}>
                {masterModuleRoutes()}
              </Route>


              <Route element={<UserViewLayout />}>
                {userModuleRoutes()}
              </Route>
            </Route>
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

/**
 * @param {any} param0
 * @return {any}
 */
export function RequireAuth({children}) {
  const [authUser] = useContext(AuthContext);
  const nav = useNavigate();
  const loading = useSelector(({user}) => user?.loading);

  useEffect(() => {
    if (!authUser) {
      nav("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser]);

  if (!authUser || loading) {
    return (
      <Loader />
    );
  }

  return (
    <DashboardLayout>
      <Outlet />
    </DashboardLayout>
  );
}

/**
 * @param {any} param0
 * @return {any}
 */
export function PreventAuth({children}) {
  const [authUser] = useContext(AuthContext);
  const nav = useNavigate();

  useEffect(() => {
    if (authUser) {
      nav("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser]);

  return (
    <AuthLayout>
      <Outlet />
    </AuthLayout>
  );
}
