/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from "react";
import {Button, Tooltip} from "antd";
import {SettingOutlined} from "@ant-design/icons";
import {useLocation, useParams} from "react-router-dom";
import {useNavigate} from "react-router-dom";

const HIDE_MENU = process.env.REACT_APP_ENV !== "prod" && process.env.REACT_APP_ENV !== "qa" &&
  process.env.REACT_APP_ENV !== "uat";

let items = [];

if (HIDE_MENU) {
  items = [
    {
      path: "profile",
      title: "Profile",

    },
    {
      title: "Contracts",
      path: "contracts",
    },
    {
      path: "proposals",
      title: "Proposals",
    },
    {
      title: "Survey Results",
      path: "surveys",
    },
    {
      path: "reviews",
      title: "Ratings & Review",
    },
    {
      path: "specialization",
      title: "Specialization",
    },
    {
      title: "Blogs",
      path: "blogs",
    },
    {
      path: "media",
      title: "Media",
    },
  ];
} else {
  items = [
    {
      title: "Profile",
      path: "profile",
    },
    {
      title: "Media",
      path: "media",
    },
    {
      path: "specialization",
      title: "Specialization",
    },
  ];
}

const itemsPending = [
  {
    title: "Profile",
    path: "profile",
  },
];

/**
 * navigation list based on vendor status
 * @return {any}
 */
export function VendorNavigationList({data}) {
  const nav = useNavigate();
  const {id} = useParams();
  const {pathname} = useLocation();

  const menu = (
    (data?.status === "pending" ||
      data?.status === "rejected") ?
      itemsPending :
      items
  )
    .map(({title, path}, i) => {
      const route = `/vendors/${id}/${path}`;
      const active = pathname.startsWith(route) ?
        "active" :
        undefined;

      return (<li
        className={active}
        onClick={() => nav(route)}
        key={i}>
        {title}
      </li>);
    });

  return (
    <>
      {data ? <div className="tabList">
        <ul>
          {menu}
        </ul>
      </div> : <></>}
    </>
  );
}
