import React, { useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";
import { VendorNavigationList }
  from "../../pages/vendors/vendor-navigation/VendorNavigationList";
import { getVendorById } from "../../services/vendor";
import { useAsyncTask } from "../../utils";
import PageNamecard from "../PageNameCard";

/**
 *
 * @param {any} param0
 * @return {any}
 */
export function VendorViewLayout() {
  const { id } = useParams();
  const [fetchVendor, { data: vendor }] = useAsyncTask(
    async () => {
      return getVendorById(id, ["VendorOwners"]);
    },
  );

  useEffect(() => {
    fetchVendor();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  return (
    <>
      <PageNamecard title="Vendors"></PageNamecard>
      <VendorNavigationList data={vendor} />
      <Outlet />
    </>
  );
}
