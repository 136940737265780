/* eslint-disable */

import React from "react";

/**
 *
 * @return {any}
 */
export default function PageNamecard(props) {
  return (
    <>
      <div className="Pagenamecard-wrp">
        <div>
          <h1>{props.title}</h1>
          <h6>{props.description}</h6>
        </div>

      </div>
    </>
  );
}
