/* eslint-disable */
import {createAsyncThunk} from "@reduxjs/toolkit";
import _ from "lodash";
import {loadAdminByEmail} from "../../services/user";
import {AppError} from "../../services/AppError";
import {isAdmin, login, logout} from "../../services/auth";
import {reset} from "./user";
import {auth} from "../../services/firebase";
import {getCurrentUserId, getPlayerId} from "../../services/notification";
import {deletePlayerID} from "../../services/vendor";

export const userLogin = createAsyncThunk(
  "user/login",
  async ({email, password, rememberMe}, thunkAPI) => {
    const response = await login(
      email.toLowerCase(),
      password,
      rememberMe,
    );

    if (!isAdmin(response?.user)) {
      thunkAPI.dispatch(userLogout());
      throw new AppError(
        AppError.USER_LOGIN_ERROR,
        "User doesn't have enough permissions to sign in",
      );
    }

    return _.pick(response.user, [
      "email",
      "uid",
    ]);
  },
);

export const userLogout = createAsyncThunk(
  "user/logout",
  async (_, thunkAPI) => {
    thunkAPI.dispatch(reset());
    const response = await logout();
    _unsubFromNotificationService();
    return response;
  },
);

export const loadUserData = createAsyncThunk(
  "user/data",
  async (_, {getState}) => {
    const {email} = auth.currentUser;
    const response = await loadAdminByEmail(email);
    if (response?.status === "disable") {
      throw new AppError(
        AppError.USER_LOGIN_ERROR,
        "Your account has been deactivated by Desi Weds."+
        "Please contact admin to activate your account"
      );
    }
    return response;
  },
  {
    condition: () => {
      if (!isAdmin(auth.currentUser)) {
        return false;
      }
    },
    dispatchConditionRejection: true,
  },
);
