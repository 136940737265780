import { React } from "react";
import { Outlet } from "react-router-dom";
import { DashboardHeader } from "../DashboardHeader";
import DashboardSideBar from "../DashboardSideBar";
import { useOnesignalSubscription } from "../../services/onesignal";


/**
 * Dashboard layout wraps around dashboard routes
 * @return {any}
 */
export function DashboardLayout() {
  useOnesignalSubscription();

  return (
    <>
      <section className="dashboardLayout">
        <DashboardHeader />
        <DashboardSideBar />
        <section className="mainContent">
          <div className="mainContent-in">
            <div className="row">
              <div className="col-12 ">
                <Outlet />
              </div>
            </div>
          </div>
        </section>
      </section >
    </>
  );
}
