import { collection, collectionGroup, getDocs, query, getDoc, doc, updateDoc, where, orderBy } from "firebase/firestore";
import { db } from "./services/firebase";
import _, { escapeRegExp } from "lodash";
import isNumber from 'is-number'
import { useReducer } from "react";
import { useSelector } from "react-redux";
import { recaptchaPublicKey } from "./config";
import { AppError } from "./services/AppError";
import * as dayjs from "dayjs";
import { getDownloadURL, ref, getStorage } from "firebase/storage";

/**
 *
 * @param {string} value
 * @param {boolean} defaultValue
 * @return {boolean}
 */
export function envBool(value, defaultValue = true) {
  if (value === undefined) {
    return defaultValue;
  }

  if (value === "true") {
    return true;
  } else if (value === "false") {
    return false;
  } else {
    throw new Error("non-boolean value found!");
  }
}

/**
 * @param {{code: string, message: string}} data
 * @return {AppError}
 */
export function transformToAppError(data) {
  return new AppError(data?.code, data.message);
}

const DEFAULT_ASYNC_STATE = {
  isLoading: false,
  isSuccess: false,
  data: undefined,
  error: undefined,
};

/**
 *
 * @param {any} state
 * @param {any} action
 * @return {any}
 */
function asyncTaskReducer(state, action) {
  let newState;

  // eslint-disable-next-line default-case
  switch (action.type) {
    case "init":
      newState = {
        ...DEFAULT_ASYNC_STATE,
        isLoading: true,
      };
      break;
    case "data":
      newState = {
        ...DEFAULT_ASYNC_STATE,
        isSuccess: true,
        data: action.payload,
      };
      break;
    case "error":
      newState = {
        ...DEFAULT_ASYNC_STATE,
        error: action.payload,
      };
      break;
    case "success":
      newState = {
        ...state,
        isSuccess: action.payload,
      };
      break;
    case "loading":
      newState = {
        ...state,
        isLoading: action.payload,
      };
      break;
  }

  return newState;
}

/**
 * react hook to handle async side effects
 * @param {Promise<any>} task
 * @return {[Promise<any>,
 *  { error: any, data: any, isLoading: boolean, isSuccess: boolean }]}
 */
export function useAsyncTask(task) {
  const setIsSuccess = (isSuccess) => {
    dispatch({ type: "success", payload: isSuccess });
  };

  const setIsLoading = (loading) => {
    dispatch({ type: "loading", payload: loading });
  };

  const setError = (error) => {
    dispatch({ type: "error", payload: error });
  };

  const [state, dispatch] = useReducer(
    asyncTaskReducer,
    DEFAULT_ASYNC_STATE,
  );

  const _task = async (...params) => {
    dispatch({ type: "init" });
    try {
      const response = await task(...params);
      dispatch({ type: "data", payload: response });
    } catch (error) {
      console.error(error);
      dispatch({ type: "error", payload: error });
    }
  };

  return [_task, { ...state, setIsSuccess, setIsLoading, setError }];
}

/**
 * Grab recaptcha token to be validated
 * @return {Promise<string>}
 */
export function getReCaptchaToken() {
  return new Promise((resolve, reject) => {
    if (!window.grecaptcha) {
      reject(new Error("grecaptcha is missing"));
    }

    window.grecaptcha.ready(async () => {
      try {
        const token = await window.grecaptcha
          .execute(recaptchaPublicKey, { action: "submit" });
        resolve(token);
      } catch (error) {
        reject(new Error("Error validating recaptcha!"));
      }
    });
  });
}

/**
 * get sub collection data from ref
 * @param {*} ref
 * @param {string} path
 * @return {any}
 */
export async function getSubCollection(ref, path) {
  const snapshot = await getDocs(
    query(
      collection(ref, path),
    ),
  );

  return _.first(snapshot.docs)?.data();
}

/**
 * get sub collection datas from ref
 * @param {*} ref
 * @param {string} path
 * @return {any}
 */
export async function getSubCollections(ref, path) {
  const snapshot = await getDocs(
    query(
      collection(ref, path),
    ),
  );

  return (snapshot.docs || []).map((r) => ({
    uid: r.id,
    ...r.data(),
  }));
}


/**
 * checks if source object exist in target object
 * property with value `undefined` get skipped to next iteration
 *
 * @example
 * objectHasPartial({ x: { r: true, w: false }, { x: { r: true } } })
 * // returns false
 *
 * @param {Object} source
 * @param {Object} target
 * @return {boolean}
 */
export function objectHasPartial(source, target) {
  if (!source) return false;

  for (const [k, v] of Object.entries(source)) {
    if (!Object.prototype.hasOwnProperty.call(target, k)) {
      return false;
    }

    const tV = target[k];

    if (typeof v === "object") {
      return objectHasPartial(v, tV);
    } else if (tV === v || typeof v === "undefined") {
      continue;
    } else {
      return false;
    }
  }

  return true;
}

/**
 * get & check user permissions
 * @return {[any, any]}
 */
export function usePermissions() {
  const user = useSelector(({ user }) => user?.user);

  const has = (partial) => {
    const role = user?.role;
    const status = user?.status;
    if (status !== "active") {
      return false;
    }
    return role === "superAdmin" ||
      isPermissionPartialMatch(partial, user?.permissions || {});
  };

  return [has, user?.permissions];
}

/**
 *
 * @param {any} partial
 * @param {any} permissions
 * @return {boolean}
 */
export function isPermissionPartialMatch(partial, permissions) {
  return objectHasPartial(partial, permissions || {});
}

/**
 * util to format to table's date format
 * @param {any} timestamp
 * @return {string}
 */
export function formatToTableDateFormat(timestamp) {
  try {
    let date;

    if (typeof timestamp === "string") {
      date = new Date(timestamp);
      if (isNaN(date)) return;
    } else {
      date = timestamp?.toDate();
    }

    return date && dayjs(date).format("MM/DD/YYYY");
  } catch (error) {
    return;
  }
}

/**
 * check if date within a range
 * @param {Date} date
 * @param {[any, any]} range
 * @return {boolean}
 */
export function dateWithinRange(date, range) {
  const [from, to] = range;

  if (from.isSame(to, "date")) {
    return from.isSame(date, "date");
  }

  return from.isSameOrBefore(date) &&
    to.isSameOrAfter(date);
}

/**
 * reload util
 * @param {number} t
 */
export function reload(t = 0) {
  window.setTimeout(() => {
    window.location.reload();
  }, t);
}

/**
 * sort by alphabets
 * @param {string} a
 * @param {string} b
 * @return {1|-1}
 */
export function sortByAlpha(a, b) {
  return (
    a?.toLowerCase() > b?.toLowerCase()
  ) ? 1 : -1;
}

/**
 * string search over list of string
 * @param {string} query
 * @return {(props: Array<string>) => boolean}
 */
export function searchLikeOverArray(query) {
  query = escapeRegExp(query).toLowerCase();

  return (props) => {
    return new RegExp(
      `.*(${query}).*`,
      "g",
    )
      .test(props?.join(" ").toLowerCase());
  }
}

export const getAllDocsOfCollection = async (collectionName, collectionType) => {
  /*  sub - subCollection , main - main collection */
  let dataQuery;
  if (collectionType === "main") {
    dataQuery = query(collection(db, collectionName), orderBy("createdAt", "desc"));
  } else {
    dataQuery = query(collectionGroup(db, collectionName), orderBy("createdAt", "desc"));
  }
  const querySnapshot = await getDocs(dataQuery);
  let dataArray = [];
  querySnapshot.forEach((doc) => {
    const eachData = doc.data();
    eachData["refId"] = doc?.id;
    dataArray.push(eachData);
  });
  return dataArray;
};

export const getVendorNameById = async (id) => {
  const docRef = doc(db, "Vendors", id);
  const docSnap = await getDoc(docRef);
  const data = docSnap.data();
  return data?.firstName + " " + data?.lastName;
}

export const updateData = async (dbName, updateData, id) => {
  try {
    let getQuery = doc(db, dbName, id);
    await updateDoc(getQuery, updateData);
    return true
  } catch (error) {
    console.log(error)
    return false
  }
}

export const getDocData = async (dbName, id) => {
  try {
    let ref = doc(db, dbName, id);
    const d = await getDoc(ref);
    
    return {
      ...d.data(),
      uid: d.id,
    };
  } catch (error) {
    console.log(error)
    return false
  }
}

export const getDataByEmail = async (email, collectionName) => {
  const snapshotD = (await getDocs(query(collection(db, collectionName), where("email", "==", email))));
  const vendor = _.first(snapshotD.docs);
  return (vendor.data());
}



export const getPublicUrl = async filepath => {
  const storage = getStorage();
  return new Promise((resolve, reject) => {
    getDownloadURL(ref(storage, filepath))
      .then((url) => {
        return resolve(url)
      })
      .catch((error) => {
        return reject(error)
      });
  });
}

/**
 * format firebase docs to array with uid
 * @param {QueryDocumentSnapshot<Document>[]} docs
 * @return {({uid: string} extends QueryDocumentSnapshot<Document>)[]}
 */
export function docsWithUid(docs) {
  return docs.map((s) => ({ uid: s.id, ...s.data() }));
}

/**
 * return the func. param on when the value len === len
 * @param {any} value
 * @param {number} len
 * @param {any} func
 * @return {any}
 */
export function onLen(value, len, func) {
  if (value?.length < len) {
    return;
  }

  return func();
}

export function formatAmount(amount) {
  let n = Number(amount);

  if (isNaN(n)) {
    return "$0";
  }

  return "$" + n.toLocaleString(
    undefined,
    {}
  );
}

export function joinAndSkipNull(arr, seperator) {
  return arr.filter(i => !!i).join(seperator);
}

/**
 * @param {string} text - Text to convert to title case
 */
export function toTitleCase(text) {
  if (text) {
    return text[0].toUpperCase() + text.slice(1);
  }
  return "";
}

/**
 * paginate an array
 *
 * @param {unknown[]} arr
 * @param {number} size
 * @param {number} page
 * @returns {unknown[]}
 */
export function paginationSlice(arr, size, page) {
  const startIndex = size * (page - 1);
  const endIndex = startIndex + size;
  return arr.slice(startIndex, endIndex);
}

/**
 * @param {number} str - value to be checked as numeric
 * @returns {boolean} weather value is numeric
 */
export function isNumeric(str) {
  return isNumber(str)
}


/**
 * @param {number} value
 * @returns {number} lowest multiple of 5
 */
export function lowestMultipleOfFive(value) {
  const multipleOf = 5;
  let mod = value % multipleOf;
  value -= mod;
  return value
}