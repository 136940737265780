import React from 'react';
import dayjs from 'dayjs'; // Make sure you have 'dayjs' imported
import PropTypes from 'prop-types';

const NotificationItem = ({ notification,onclick }) => {
    const createdAtDayjs = dayjs.unix(notification.createdAt.seconds);
    const formattedTime = createdAtDayjs.format('hh:mm A');
    const formattedDate = createdAtDayjs.format('MM/DD/YY');

    return (
        <li className="new" key={notification.id}>
            <div className="notificationtext" onClick={onclick}>
                <strong>{notification.content}</strong>
                <span>{formattedDate} {formattedTime}</span>
            </div>
        </li>
    );
};

NotificationItem.propTypes = {
    notification: PropTypes.object.isRequired,
    onclick:PropTypes.func.isRequired


};
export default NotificationItem;

