/**
 * Error class for the App
 */
export class AppError {
  static USER_LOGIN_ERROR = "USER_LOGIN_ERROR";
  static USER_PASSWORD_RESET_FAILED = "USER_PASSWORD_RESET_FAILED";
  static USER_CLAIM_SET_FAILED = "USER_CLAIM_SET_FAILED";
  static USER_CLAIM_DELETE_FAILED = "USER_CLAIM_DELETE_FAILED";
  static DATA_CREATE_FAILED = "DATA_CREATE_FAILED";
  static DATA_FETCH_FAILED = "DATA_FETCH_FAILED";
  static DATA_UPDATE_FAILED = "DATA_UPDATE_FAILED";
  static DATA_DELETE_FAILED = "DATA_DELETE_FAILED";
  static CONTRACT_FETCHING_FAILED = "CONTRACT_FETCHING_FAILED";
  static DEFAULT_DATA_MISSING = "DEFAULT_DATA_MISSING";
  static DISABLE_PLAN_FAILED = "DISABLE_PLAN_FAILED";
  static ENABLE_PLAN_FAILED = "ENABLE_PLAN_FAILED";
  static UPDATE_CALL_CHARGE = "UPDATE_CALL_CHARGE";
  static PUSH_NOTIFICATION_FAILED = "PUSH_NOTIFICATION_FAILED";
  static TODO_CHANGE_NOTIFICATION_FAILED = "TODO_CHANGE_NOTIFICATION_FAILED";

  /**
   *
   * @param {string} code
   * @param {string} message
   * @param {any} original
   */
  constructor(code, message, original) {
    this.code = code;
    this.message = message;
    original && console.error(original);
  }
}
