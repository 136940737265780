import {createContext, React, useEffect} from "react";
import {Outlet} from "react-router-dom";
import {auth} from "./firebase";
import {useAuthState} from "react-firebase-hooks/auth";
import {useDispatch, useSelector} from "react-redux";
import {init} from "../store/auth/user";
import {Loader} from "../components/common/Loader";

export const AuthContext = createContext();

/**
 * Provide the context provider
 * @param {any} props
 * @return {any}
 */
export function AuthProvider(props) {
  const [user, loading, error] = useAuthState(auth);
  const dispatch = useDispatch();
  const userData = useSelector(({user}) => user);

  useEffect(() => {
    if (user && loading === false && userData.loading === undefined) {
      dispatch(init());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, loading]);

  if (loading) {
    return (<Loader />);
  }

  return (
    <AuthContext.Provider value={[user, loading, error]} {...props}>
      <Outlet />
    </AuthContext.Provider>
  );
}
