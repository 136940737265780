import {createListenerMiddleware, isAnyOf} from "@reduxjs/toolkit";
import {loadUserData, userLogin} from "./thunks";
import {init} from "./user";

const loadUserDataMiddleware = createListenerMiddleware();

loadUserDataMiddleware.startListening({
  matcher: isAnyOf(userLogin.fulfilled, init),
  effect: (_, {dispatch}) => {
    dispatch(loadUserData());
  },
});

export default loadUserDataMiddleware.middleware;
