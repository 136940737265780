import React, { useEffect, useState ,useCallback } from "react";
import { Button, Spin, Table, Modal } from "antd";
import { UserCard } from "../../../components/common/UserCard";
import { formatAmount, formatToTableDateFormat, useAsyncTask } from "../../../utils";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getContract } from "../../../services/vendorContracts";
import { getMainEvent, getMyEvents} from "../../../services/mainEvents";
import dayjs from "../../../utils/day";
import { getUserFromId } from "../../../services/users";
import LegalAgreement from "../../users/legal-agreement/LegalAgreement"
import PropTypes from 'prop-types';

const VendorProposalDetails = () => {
  console.log("inise");
  const { proposalId } = useParams();
  const nav = useNavigate();
  const [fetchData, { data, isLoading }] = useAsyncTask(async () => {
    const contract = await getContract(proposalId);
    const events = await getMyEvents(contract.mainEventId);
    console.log("events",events);
    const mainEvent = await getMainEvent(contract.mainEventId);
    const bride = await getUserFromId(contract.brideId);
    const groom = await getUserFromId(contract.groomId);
    return { events, contract, bride, groom, mainEvent };
  });
  
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log(data);
  return (
    <Spin spinning={isLoading}>
      <div className="profileCard">
        <div className="row">
          <div className="col-12 col-lg-4 col-xl-3">
            <div className="profileCard-left">
              <BrideAndGroomCard data={data} />
            </div>
          </div>
          <div className="col-12 col-lg-8 col-xl-9">
            <div className="profileCard-right">
              <div className="vendorProposalContent">
                <div className="verifybtnsGrp">
                  <Button onClick={() => nav(-1)}
                    className="outlineBtn float-start" >Back</Button>
                  <div className="clearfix"></div>
                </div>
                <div className="profileListDetails">
                  <WeddingDetail data={data} />
                </div>
                <div className="detailedContent">
                  <div className="tableHead">
                    <ScheduleLine data={data} />
                  </div>
                  <EventsTable data={data} />
                  <ProposalNote data={data} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
};

const columns = [
  // {
  //   title: "Day",
  //   dataIndex: "day",
  //   width: 100,
  // },
  {
    title: "Event Name",
    dataIndex: "eventName",
    width: 130,
  },
  {
    title: "Date",
    dataIndex: "eventDate",
    width: 130,
    render: (text) => formatToTableDateFormat(text),
  },
  {
    title: "Time",
    dataIndex: "eventDate",
    width: 130,
    render: (text) => {
      const formattedTime = text && dayjs(text?.toDate()).format("hh:mm A");
      return formattedTime === "12:00 AM" ? "" : formattedTime;
    },
  },
  {
    title: "Venue",
    dataIndex: "venue",
    width: 130,
  },
];

/**
 * events table
 * @return {any}
 */
export function EventsTable({ data, ...props }) {
  return (
    <div className="table-wrp no-bg-table">
      <Table
        columns={columns}
        rowKey="uid"
        dataSource={data?.events?.sort((a, b) => {
          if (!a?.eventDate || !b?.eventDate) {
            return a?.eventDate || b?.eventDate;
          }

          return  a?.eventDate?.toDate() - b?.eventDate?.toDate();
        })}
        pagination={false}
        scroll={{ y: "auto" }}
        {...props}
      />
    </div>
  );
}

/**
 *
 * @return {any}
 */
export function BrideAndGroomCard({ data, showAddress }) {
  return (
    <>
      {data?.bride && <UserCard
        showAddress={showAddress}
        data={data?.bride} />}
      {data?.groom && <UserCard
        showAddress={showAddress}
        data={data?.groom} />}
    </>
  );
}

/**
 *
 * @return {any}
 */
function ProposalNote({ data }) {
  return (
    <>
      <h3>Note</h3>
      <p>{data?.contract.note}</p>
    </>
  );
}

/**
 * wedding profile
 * @return {any}
 */
export function WeddingDetail({ data }) {
  console.log("data1",data);
  const { pathname } = useLocation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const close = useCallback(() => {
    setIsModalVisible(false);
  }, [setIsModalVisible]);

  const handleToggleClick = useCallback(() => {
    setIsModalVisible(true);
  }, [setIsModalVisible]);
  
  const ceremonyDate = formatToTableDateFormat(
    data?.mainEvent?.date || data?.contract?.mainEventDate,
  );

  return (
    <>
    <div className="row">
      <div className="col-12 col-md-6 col-lg-4">
        <h5>
          <span>Bride Religion</span>
          {data?.bride?.religion}
        </h5>
      </div>
      <div className="col-12 col-md-6 col-lg-4">
        <h5>
          <span>Groom Religion</span>
          {data?.groom?.religion}
        </h5>
      </div>
      <div className="col-12 col-md-6 col-lg-4">
        <h5>
          <span>Ceremony Date</span>
          {ceremonyDate}
        </h5>
      </div>
      <div className="col-12 col-md-6 col-lg-4">
        <h5>
          <span>Total Budget</span>
          {formatAmount(data?.mainEvent?.budgetPlan?.totalBudget)}
        </h5>
      </div>
      <div className="col-12 col-md-6 col-lg-4">
        <h5>
          <span>Total Allocated</span>
          {formatAmount(data?.budgetSplitTotalValue)}
        </h5>
      </div>
      <div className="col-12 col-md-6 col-lg-4">
        <h5>
          <span>Total Spent</span>
          {formatAmount(data?.sumInvoiceAmount)}
        </h5>
      </div>

      {data?.mainEvent?.subscription?.plan?.planType === "paid" && <>

        <div className="col-12 col-md-6 col-lg-4">
          <h5>
            <span>Subscription End Date</span>
            {formatToTableDateFormat(data?.mainEvent?.subscription?.nextBillingDate) || "No Data"}
          </h5>
        </div>

        {data?.mainEvent?.subscription?.cancelAtPeriodEnd && <div className="col-12 col-md-6 col-lg-4">
          <h5>
            <span>Canceled On</span>
            {formatToTableDateFormat(data?.mainEvent?.subscription?.cancelledOn) || "No Data"}
          </h5>
        </div>}
      </>}
    </div>
    {data?.mainEvent?.acceptCategoryAgreement && pathname.includes('/contracts') &&
    <>
      <Button
        onClick={handleToggleClick}
        className="cmnBtn mt-3 mb-3">
        View DW Legal Agreement
      </Button>
      <Modal
        title="Vendor Matching Agreement"
        visible={isModalVisible}
        onCancel={close}
        centered
        className="cmnModal"
        footer={[
          <Button type="button"
            className="outlineBtn"
            key="back"
            onClick={close}>
            Close
          </Button>
          ]}
      >
        <LegalAgreement />
      </Modal>
    </>
    }
</>

  );
}
WeddingDetail.propTypes = {
  data: PropTypes.object.isRequired,
};

/**
 * @param {any} param0
 * @return {any}
 */
function ScheduleLine({ data }) {
  const contractDate = formatToTableDateFormat(data?.contract?.createdAt);
  let statusDateLine;

  if (data?.contract.status === "cancelled") {
    statusDateLine = (
      <>
        | <strong>Canceled on</strong>{":"} {
          formatToTableDateFormat(data?.contract.cancelledAt)}
      </>
    );
  } else if (data?.contract.status === "rejected") {
    statusDateLine = (
      <>
        | <strong>Rejected on</strong>{":"} {
          formatToTableDateFormat(data?.contract.rejectedAt)}
      </>
    );
  }

  return (
    <>
      <h3>Schedule </h3>
      <span>
        <strong>Request on</strong>
        : {contractDate} {statusDateLine}
      </span>
    </>
  );
}

export default VendorProposalDetails;
