import React from "react";
import {Outlet} from "react-router-dom";
import {Logo} from "../common/Logo";

/**
 *
 * @param {any} param0
 * @return {any}
 */
export function AuthLayout({children}) {
  return (
    <>
      <div className="signin-wrp">
        <div className="bgPart">
          <img alt="banner" src="./images/auth-banner.jpg"></img>
        </div>
        <div className="formPart">
          <div className="form-wrap">
            <Logo />
            <h4>DesiWeds Admin Portal</h4>
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
}
