import React from "react";

/**
 * Logout icon
 * @return {any}
 */
export default function LogoutIcon() {
  return (
    <>
      <div className="icon-wrp">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12.804"
          height="10.746"
          viewBox="0 0 12.804 10.746"
        >
          <g id="menuicon2" transform="translate(-17.416 -17.402)">
            <path
              className="fillcss"
              id="Path_16802"
              data-name="Path 16802"
              // eslint-disable-next-line max-len
              d="M22.789,28.148a5.359,5.359,0,0,0,3.8-1.572.412.412,0,0,0-.583-.583,4.552,4.552,0,1,1,0-6.437.412.412,0,0,0,.583-.583,5.376,5.376,0,1,0-3.8,9.174Z"
              transform="translate(0 0)"
            />
            <path
              className="fillcss"
              id="Path_16803"
              data-name="Path 16803"
              // eslint-disable-next-line max-len
              d="M55.238,38.728h0l-1.754-1.754a.412.412,0,0,0-.583.583l1.052,1.052H47.928a.412.412,0,1,0,0,.824h6.025L52.9,40.483a.412.412,0,0,0,.583.583l1.755-1.755a.412.412,0,0,0,.051-.52A.4.4,0,0,0,55.238,38.728Z"
              transform="translate(-25.139 -16.245)"
            />
          </g>
        </svg>
      </div>
    </>
  );
}
