import React, { lazy } from "react";
import { Route } from "react-router-dom";

const routes = [
  {
    path: "/users/:id/events",
    component: lazy(() => import("../pages/users/user-events/UserEvents")),
  },
  {
    path: "/users/:id/proposals",
    component: lazy(() => import("../pages/users/user-proposals/UserProposals")),
  },
  {
    path: "/users/:id/proposals/:uid",
    component: lazy(() => import("../pages/users/user-proposals/UserProposalDetails")),
  },
  {
    path: "/users/:id/contracts",
    component: lazy(() => import("../pages/users/user-contracts/UserContracts")),
  },
  {
    component: lazy(() => import("../pages/users/UserMeetingShedule")),
    path: "/users/:id/schedules",
  },
  {
    path: "/users/:id/usertodos",
    component: lazy(() => import("../pages/users/user-todos/UserToDos")),
  },
  {
    path: "/users/:id/userpreferences",
    component: lazy(() => import("../pages/users/user-preferences/UserPreferences")),
  },
];

export const userModuleRoutes = () => {
  return (
    <React.Fragment>
      {routes.map((route, i) => {
        return (
          <Route
            key={i}
            path={route.path}
            element={<route.component />} />
        );
      })}
    </React.Fragment>
  );
};
