import axios from "axios";
import { baseAPIUrl } from "../config";
import { getidtoken } from "../services/auth";
import { auth } from "../services/firebase";

export async function firebaseAuthTokenInterceptor(config) {
  const user = auth.currentUser;

  if (user) {
    const token = await getidtoken();
    config.headers["Authorization"] = "Bearer " + token;
  }

  return config;
}

export const client = axios.create(
  {
    baseURL: baseAPIUrl,
    headers: {
      "accept": "application/json",
    },
    transitional: {
      silentJSONParsing: true,
    },
  },
);

client.interceptors.request.use(firebaseAuthTokenInterceptor);