import {combineReducers, configureStore} from "@reduxjs/toolkit";
import failSafeLogoutMiddleware from "./auth/failSafeLogoutMiddleware";
import loadUserDataMiddleware from "./auth/loadUserDataMiddleware";
import userReducer from "./auth/user";
import cityReducer  from "./city/city"
const combined = combineReducers({
  user: userReducer,
  city:cityReducer
});

const rootReducer = (state, action) => {
  return combined(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .prepend(failSafeLogoutMiddleware)
      .prepend(loadUserDataMiddleware),
});

export default store;
