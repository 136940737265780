import React, {lazy} from "react";
import {Route} from "react-router-dom";
import PermissionComponent from "./PermissionComponent";

const routes = [
  {
    component: lazy(() => import("../pages/vendors/VendorProfile")),
    path: "/vendors/:id/profile",
  },
  {
    component: lazy(() => import("../pages/vendors/VendorSpecialization")),
    path: "/vendors/:id/specialization",
  },
  {
    component: lazy(() =>
      import("../pages/vendors/vendor-blog/VendorBlogDetails")),
    path: "/vendors/:id/blogs/:blogId",
  },
  {
    component: lazy(() =>
      import("../pages/vendors/vendor-blog/VendorBlogListing")),
    path: "/vendors/:id/blogs",
  },
  {
    component: lazy(() => import("../pages/vendors/VendorSettings")),
    path: "/vendors/:id/settings",
  },
  {
    component: lazy(() => import("../pages/vendors/VendorMedia")),
    path: "/vendors/:id/media",
  },
  {
    component: lazy(() =>
      import("../pages/vendors/vendor-contracts/VendorContracts")),
    path: "/vendors/:id/contracts",
  },
  {
    component: lazy(() =>
      import("../pages/vendors/vendor-proposals/VendorProposalsListing")),
    path: "/vendors/:id/proposals",
  },
  {
    component: lazy(() =>
      import("../pages/vendors/vendor-proposals/VendorProposalDetails")),
    path: "/vendors/:id/proposals/:proposalId",
  },
  {
    component: lazy(() => import("../pages/vendors/VendorRatingReview")),
    path: "/vendors/:id/reviews",
  },
  {
    component: lazy(() => import("../pages/vendors/VendorSurveyResult")),
    path: "/vendors/:id/surveys",
  },
  {
    component: lazy(() => import("../pages/vendors/vendor-blog/VendorBlogListing")),
    path: "/vendors/:id/blogs",
  },
  {
    component: lazy(() => import("../pages/vendors/vendor-blog/VendorBlogDetails")),
    path: "/vendors/:id/blogs/:blogId",
  },
];

export const vendorRoutes = () => {
  return (
    <React.Fragment>
      {routes.map((route, i) => {
        return (
          <Route
            key={i}
            path={route.path}
            element={<PermissionComponent
              permission={route.permission}
              element={route.component} />} />
        );
      })}
    </React.Fragment>
  );
};
