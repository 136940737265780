
import React from 'react';

const ViewAgreement = () => {

  return (
    <>        <div className="agreement-section ">
              <p>
                By using or accessing the Services, you confirm your agreement
                to be bound by this Buyer Agreement, our Terms and Conditions,
                and our Privacy Policy. If you do not agree to these, please do
                not use or access the Services.{" "}
              </p>
           

            <p>
              As a Member, you acknowledge that while we use techniques to help
              identify the best Wedding Professionals for your wedding, you have
              the right to select your own Wedding Professional in any category.
              DesiWeds does endorse Wedding Professionals and stands behind the
              quality of their goods or services only when they are contracted
              through our platform. If a Member utilizes the DesiWeds platform
              exclusively for research purposes, we cannot be held responsible
              if a Wedding Professional fails to provide Member with the
              services contracted outside of the DesiWeds platform.
            </p>

            <p className="m-0">
              When utilizing our services - you, the Member, agree to the
              following:{" "}
            </p>

            <ol>
              <li className="mt-5px">
                Member grants DesiWeds, LLC (DesiWeds) the exclusive right to
                act as Member’s intermediary for the purpose of hiring Wedding
                Professionals.{" "}
              </li>
              <li>
                DESIWEDS’ OBLIGATIONS: (a) use best efforts to understand the
                needs of the Member as it relates to recommending Wedding
                Professionals (b) assist Member in hiring Wedding Professionals
                and (c) comply with other provisions of this agreement.{" "}
              </li>
              <li>
                MEMBER’S OBLIGATIONS: (a) work exclusively through DesiWeds in
                hiring wedding professionals (b) inform DesiWeds if a Wedding
                Professional connects with them outside of the site/platform;
                and (c) comply with other provisions of this agreement.{" "}
              </li>
              <li>
                <div>REPRESENTATIONS: </div>
                <ol type="A">
                  <li>
                    Each person signing this agreement represents that said
                    person has the legal capacity and authority to bind the
                    respective party to this agreement.{" "}
                  </li>
                  <li>
                    Member represents that all information relating to Member’s
                    desire to hire a Wedding Professional given to DesiWeds is
                    true and correct.{" "}
                  </li>
                </ol>
              </li>
              <li>
                COMPETING MEMBERS: Member acknowledges that DesiWeds may
                represent others who may seek to hire Wedding Professionals that
                may also be of interest to Member. Member agrees that DesiWeds
                may, during the term of this agreement and after it ends,
                represent such others in the same manner as said Member.
              </li>
              <li>
                <div>DesiWeds’ FEES: </div>
                <ol type="A">
                  <li>
                    Referral Fee: The parties agree that DesiWeds will receive a
                    fee calculated as follows: (1) Percentage of the gross sales
                    price upon Member signing contract with Wedding Professional
                  </li>
                  <li>
                    Source of Payment: DesiWeds will obtain fee payment directly
                    from the Wedding Professional.{" "}
                  </li>
                  <li>
                    A Member breaches this agreement when they utilize DesiWeds’
                    services to be matched with vendors but then signs the
                    contract with said Wedding Professional outside of DesiWeds’
                    platform. If a Member breaches this agreement, the referral
                    fee will be due and payable by the Member immediately.{" "}
                  </li>
                </ol>
              </li>
              <li>
                MEDIATION: The parties agree to negotiate in good faith in an
                effort to resolve any dispute that may arise related to this
                agreement or any transaction related to or contemplated by this
                agreement. If the dispute cannot be resolved by negotiation, the
                parties will submit the dispute to mediation before resorting to
                arbitration or litigation and will equally share the costs of a
                mutually acceptable mediator.{" "}
              </li>
              <li>
                DEFAULT: If either party fails to comply with this agreement or
                makes a false representation in this agreement, the
                non-complying party is in default. If Member is in default,
                DesiWeds may terminate this agreement and Member will be liable
                for the amount of compensation that DesiWeds would have received
                under this agreement if Member was not in default; DesiWeds may
                also terminate this agreement and exercise any other remedy at
                law. If DesiWeds is in default, Member may exercise any remedy
                at law.{" "}
              </li>
              <li>
                ATTORNEY’S FEES: If Member or DesiWeds is a prevailing party in
                any legal proceeding brought as a result of a dispute under this
                agreement or any transaction related to this agreement, such
                party will be entitled to recover from the non-prevailing party
                all costs of such proceeding and reasonable attorney’s fees.{" "}
              </li>
              <li>
                LIMITATION OF LIABILITY: Neither DesiWeds or their associates,
                is responsible or liable for any person’s personal injuries or
                for any loss or damage to any person’s property. Member will
                hold DesiWeds and their associates, harmless from any such
                injuries or losses. Member will indemnify DesiWeds against any
                claims for injury or damage that Member may cause to others or
                their property.
              </li>
            </ol>
            </div>
            </>    
  );
}
export default ViewAgreement;

