import { Provider } from "react-redux";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import Router from "./routes/Router";
import store from "./store";
import { envBool } from "./utils";
import { React } from "react";
import {useOnesignalInit} from "./services/onesignal";
const { REACT_APP_LOGGING, REACT_APP_SENTRY_DSN } = process.env;

if (envBool(REACT_APP_LOGGING, false)) {
  Sentry.init({
    dsn: REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

/**
 * @return {any}
 */
function App() {
  useOnesignalInit();

  return (
    <div className="App">
      <Provider store={store}>
        <Router />
      </Provider>
    </div>
  );
}

export default App;
