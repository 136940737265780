import axios from "axios";
import { firebaseAuthTokenInterceptor } from "../api";
import { chatApiAddUserUrl, chatApiFlaggedListingUrl } from "../config";

/**
 * add usser to the chat
 * @param {string} uuid 
 */
export async function addUser(email) {
    const config = await firebaseAuthTokenInterceptor({ headers: {} });

    return axios.post(
        chatApiAddUserUrl,
        { email },
        config
    );
}

/**
 * add usser to the chat
 * @param {string} uuid 
 */
export async function getFlaggedMessages(page, userId) {
    const config = await firebaseAuthTokenInterceptor({ headers: {} });
    userId = userId ? window.btoa(userId) : undefined;

    return axios.get(
        chatApiFlaggedListingUrl,
        { params: { page, userId }, ...config },
    );
}