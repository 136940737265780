import React, {useEffect, useMemo, useState} from "react";
import {Menu} from "antd";
import {useLocation, useNavigate} from "react-router-dom";
import {usePermissions} from "../utils";

const Icon = (props) => <img alt={props.alt} {...props}></img>;
let items = [];
if (process.env.REACT_APP_ENV != "prod" && process.env.REACT_APP_ENV != "uat" && process.env.REACT_APP_ENV!="qa") {
  items = [
    {
      key: "dashboard",
      label: "Dashboard",
      icon: <Icon src="/images/dashboard.svg" />,
      url: "/",
      permission: {
        dashboard: undefined,
      },
    },
    {
      key: "vendors",
      label: "Vendors",
      icon: <Icon src="/images/vendor.svg" />,
      url: "/vendors",
      permission: {
        dashboard: {
          vendors: true,
        },
      },
    },
    {
      key: "users",
      label: "Users",
      icon: <Icon src="/images/user.svg" />,
      url: "/users",
      permission: {
        vendors: undefined,
      },
    },
    {
      key: "contracts",
      label: "Contracts",
      icon: <Icon src="/images/contracts.svg" />,
      url: "/contracts",
      permission: {
        contracts: undefined,
      },
    },
    // {
    //   key: "payments",
    //   label: "Payments",
    //   icon: <Icon src="/images/payment.svg" />,
    //   url: "/payments",
    //   permission: {
    //     payments: undefined,
    //   },
    // },
    // {
    //   key: "questionaires",
    //   label: "Questionaires",
    //   icon: <Icon src="/images/questionaire.svg" />,
    //   url: "/questionaires",
    //   permission: {
    //     questionaires: undefined,
    //   },
    // },
    {
      key: "sub_admins",
      label: "Sub Admin",
      icon: <Icon src="/images/subadmin.svg" />,
      url: "/sub-admins",
      permission: {
        subAdmins: undefined,
      },
    },
    {
      key: "master_modules",
      label: "Master Modules",
      icon: <Icon src="/images/master-modules.svg" />,
      url: "/master-modules",
      permission: {
        masterModules: undefined,
      },
    },
    {
      key: "schedules",
      label: "My Schedules",
      icon: <Icon src="/images/schedules.svg" />,
      url: "/schedules",
      permission: {
        schedules: undefined,
      },
    },
    // {
    //   key: "blogs",
    //   label: "Blogs",
    //   icon: <Icon src="/images/blog.svg" />,
    //   url: "/blogs",
    //   permission: {
    //     blogs: undefined,
    //   },
    // },
    {
      key: "References",
      label: "References",
      icon: <Icon src="/images/reference.svg" />,
      url: "/references",
      permission: {
        vendors: undefined,
      },
    },
    {
      key: "VendorContracts",
      label: "Vendor Contracts",
      icon: <Icon src="/images/vendorcontractsicon.svg" />,
      url: "/VendorContracts",
      permission: {
        vendors: undefined,
      },
    },
    {
      key: "ChatReports",
      label: "Chat Reports",
      icon: <Icon src="/images/vendorcontractsicon.svg" />,
      url: "/chat-reports",
      permission: {
        vendors: undefined,
      },
    },
  ];

} else {
  items = [
    {
      key: "dashboard",
      label: "Dashboard",
      icon: <Icon src="/images/dashboard.svg" />,
      url: "/",
      permission: {
        dashboard: undefined,
      },
    },
    {
      key: "vendors",
      label: "Vendors",
      icon: <Icon src="/images/vendor.svg" />,
      url: "/vendors",
      permission: {
        dashboard: {
          vendors: true,
        },
      },
    },
    {
      key: "users",
      label: "Users",
      icon: <Icon src="/images/user.svg" />,
      url: "/users",
      permission: {
        vendors: undefined,
      },
    },
    {
      key: "master_modules",
      label: "Master Modules",
      icon: <Icon src="/images/master-modules.svg" />,
      url: "/master-modules",
      permission: {
        masterModules: undefined,
      },
    },
    {
      key: "schedules",
      label: "My Schedules",
      icon: <Icon src="/images/schedules.svg" />,
      url: "/schedules",
      permission: {
        schedules: undefined,
      },
    },
    {
      key: "References",
      label: "References",
      icon: <Icon src="/images/reference.svg" />,
      url: "/references",
      permission: {
        vendors: undefined,
      },
    },
    // {
    //   key: "VendorContracts",
    //   label: "Vendor Contracts",
    //   icon: <Icon src="/images/vendorcontractsicon.svg" />,
    //   url: "/VendorContracts",
    //   permission: {
    //     vendors: undefined,
    //   },
    // }
  ];
}

/**
 * check if path is relative to
 * @param {string} root
 * @param {string} relative
 * @return {boolean}
 */
function _isRelativeTo(root, relative) {
  if (relative === root) {
    return true;
  }

  const relF = relative
    .split("/")
    .filter((u) => u.length)[0];
  const rootF = (root.split("/")
    .filter((u) => u.length))[0];
  return rootF === relF;
}

/**
 *
 * Dashboard sidebar
 * @return {any}
 */
export default function DashboardSideBar() {
  const navigate = useNavigate();
  const [has, permissions] = usePermissions();
  const [selectedKey, setSelectedKey] = useState();
  const location = useLocation();

  useEffect(() => {
    const key = items
      .find(
        ({url}) => _isRelativeTo(url, location.pathname),
      )?.key;
    setSelectedKey(key);
  }, [location]);

  const onItemClick = ({key}) => {
    const item = items.find((i) => i.key === key);

    if (item) {
      navigate(item.url);
    }
  };

  const menuItems = useMemo(() => {
    return items.filter((item) => {
      return has(item?.permission);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissions]);

  return (
    <section className="sideMenu">
      <Menu items={menuItems}
        onClick={onItemClick}
        selectedKeys={[selectedKey]} mode="inline">
      </Menu>
    </section>
  );
}
