import {addDoc, collection, deleteDoc, doc, getDocs, increment, query, serverTimestamp, updateDoc, where, orderBy,getDoc} from "firebase/firestore";
import _ from "lodash";
import {AppError} from "./AppError";
import {db} from "./firebase";

/**
 * Fetch relegions
 */
export async function getReligions() {
  const snapshot = await getDocs(
    query(
      collection(db, "Religions"),
      where("deleted", "==", false),
      orderBy("name","asc")
    ),
  )
    .catch((e) => {
      throw new AppError(
        AppError.DATA_FETCH_FAILED,
        "Failed to fetch data.",
      );
    });

  return snapshot.docs.map((s) => ({uid: s.id, ...s.data()}));
}

export async function getReligionsForMultiSelect() {
  let data = []
  const snapshot = await getDocs(
    query(
      collection(db, "Religions"),
      where("deleted", "==", false),
      orderBy("name","asc")
    ),
  )
    .catch((e) => {
      throw new AppError(
        AppError.DATA_FETCH_FAILED,
        "Failed to fetch data.",
      );
    });
    if (snapshot) {
      snapshot.forEach((qs) => {
        data.push({ value: qs.id, label: qs.data().name });
      })
    }
    return (data);
}

/**
 * Fetch sub-religions
 */
export async function getSubReligions(religionUID) {
  const snapshot = await getDocs(
    query(
      collection(db, "SubReligions"),
      where("religionId", "==", religionUID),
      where("deleted", "==", false),
      orderBy("name", "asc")
    ),
  )
    .catch((e) => {
      throw new AppError(
        AppError.DATA_FETCH_FAILED,
        "Failed to fetch data.",
      );
    });

  return snapshot.docs.map((s) => ({uid: s.id, ...s.data()}));
}

/**
 * add new religion
 * @param {any} data
 */
export async function createReligion(data) {
  await addDoc(
    collection(db, "Religions"),
    {
      ...data,
      createdAt: serverTimestamp(),
      status: "active",
      deleted: false,
    },
  )
    .catch((e) => {
      throw new AppError(
        AppError.DATA_UPDATE_FAILED,
        "Failed update/add a religion.",
      );
    });
}

/**
 * update religion
 * @param {any} data
 */
export async function updateReligion(data) {
  await updateDoc(
    doc(db, "Religions", data?.uid),
    _.omit(data, ["uid"]),
  )
    .catch((e) => {
      throw new AppError(
        AppError.DATA_UPDATE_FAILED,
        "Failed update/add a city.",
      );
    });
}

/**
 * add new sub religion
 * @param {any} data
 */
export async function createSubReligion(data) {
  await addDoc(
    collection(db, "SubReligions"),
    {
      ...data,
      createdAt: serverTimestamp(),
      status: "active",
      deleted: false,
    },
  )
    .catch((e) => {
      throw new AppError(
        AppError.DATA_UPDATE_FAILED,
        "Failed update/add a sub religion.",
      );
    });

  await updateSubCount(data?.religionId, "increment");
}

/**
 * update sub religion
 * @param {any} data
 */
export async function updateSubReligion(data) {
  await updateDoc(
    doc(db, "SubReligions", data?.uid),
    _.omit(data, ["uid"]),
  )
    .catch((e) => {
      throw new AppError(
        AppError.DATA_UPDATE_FAILED,
        "Failed update/add a sub religion.",
      );
    });
}

/**
 * delete religion
 * @param {string} religionId
 * @param {string} uid
 */
export async function deleteSubReligion(religionId, uid) {
  await deleteDoc(
    doc(db, "SubReligions", uid),
  )
    .catch((e) => {
      throw new AppError(
        AppError.DATA_DELETE_FAILED,
        "Failed delete a sub religion.",
      );
    });

  await updateSubCount(religionId, "decrement");
}

/**
 * update religion
 * @param {any} uid
 */
export async function deleteReligion(uid) {
  await deleteDoc(
    doc(db, "Religions", uid),
  )
    .catch((e) => {
      throw new AppError(
        AppError.DATA_DELETE_FAILED,
        "Failed delete a city.",
      );
    });
}


/**
 * 
 * @param {string} religionUID 
 * @param {"increment"|"decrement"} type 
 */
export function updateSubCount(religionUID, type) {
  const q = type === "increment" ? 1 : -1;
  return updateReligion({
    uid: religionUID,
    subReligionCount: increment(q)
  });
}

/**
 * check if religion belongs to any of the vendors
 * @param {string} value
 * @param {string} label
 * @return {Promise<boolean>}
 */
export async function religionExistsInVendor(value, label) {
  const snapshot = await getDocs(
    query(
      collection(db, "Vendors"),
      where("expertisedReligion", "array-contains", {label, ...(value ? {value} : {})}),
    ),
  );

  return !snapshot.empty;
}

/**
 * check if religion belongs to any of the vendors
 * @param {string} value
 * @param {string} label
 * @return {Promise<boolean>}
 */
 export async function subReligionExistsInVendor(value, label) {
  const snapshot = await getDocs(
    query(
      collection(db, "Vendors"),
      where("expertisedReligion", "array-contains", {label, ...(value ? {value} : {})}),
    ),
  );

  return !snapshot.empty;
}


export async function getReligionById(religionId) {
  const religionRef = doc(collection(db, "Religions"), religionId);
  const religionSnapshot = await getDoc(religionRef)
    .catch((e) => {
      throw new AppError(
        AppError.DATA_FETCH_FAILED,
        "Failed to fetch data.",
      );
    });

  if (religionSnapshot.exists()) {
    return { uid: religionSnapshot.id, ...religionSnapshot.data() };
  } else {
    // Handle the case where the document does not exist
    return null;
  }
}