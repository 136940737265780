import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  getDoc,
  query,
  serverTimestamp,
  updateDoc,
  where
}
  from "firebase/firestore";
import {AppError} from "./AppError";
import {db} from "./firebase";
import cities from "../config/cities.json";
import _ from "lodash";
import { setCities } from "../store/city/city";
/**
 * get states list
 */
export async function getStates() {
  const snapshot = await getDocs(
    query(
      collection(db, "States"),
      where("status", "==", "active"),
    ),
  )
    .catch((e) => {
      throw new AppError(
        AppError.DATA_FETCH_FAILED,
        "Failed to fetch cities.",
      );
    });

  return snapshot.docs.map((s) => ({uid: s.id, ...s.data()}));
}

/**
 * get cities list
 */
export async function getCitiesList(stateUid) {
  const snapshot = await getDocs(
    query(
      collection(db, "Cities"),
      ...(stateUid ? [where("stateId", "==", stateUid)] : []),
      where("deleted", "==", false)
    ),
  )
    .catch((e) => {
      throw new AppError(
        AppError.DATA_FETCH_FAILED,
        "Failed to fetch cities.",
      );
    });

  return snapshot.docs.map((s) => ({uid: s.id, ...s.data()}));
}



/**
 * get cities list using prefix 
 * prefix should be zipcode,cityname or statename
 * isCityOrZipcodeorState should be city,state or zipcode
 */
export const searchCitiesByCityStateZipcode = async (dispatch, prefix, isCityOrZipcodeorState) => {
  try {
    let citiesQuery;
    console.log("insie searchCitiesByCityStateZipcode-->",prefix, isCityOrZipcodeorState);
    if (isCityOrZipcodeorState === "city") {
      citiesQuery = query(
        collection(db, "Cities"),
        where("deleted", "==", false),
        where("name", ">=", prefix),
        where("name", "<", prefix + "z")
      );
    } 
    else if(isCityOrZipcodeorState === "state")
    {
      citiesQuery = query(
        collection(db, "Cities"),
        where("deleted", "==", false),
        where("stateName","==", prefix)
      );
    }
    else {
      citiesQuery = query(
        collection(db, "Cities"),
        where("deleted", "==", false),
        where("zipcodes", "array-contains", prefix)
      );
    }

    const snapshot = await getDocs(citiesQuery);

    const cities = snapshot.docs.map((doc) => {
      const id = doc.id;
      const { name, zipcodes } = doc.data();
      return {
        id,
        value: id,
        label: name,
        zipcodes: zipcodes ?? [],
        ...doc.data(),
      };
    });
   console.log("citites",cities);
    dispatch(setCities({ cities }));
  } catch (error) {
    console.error(error);
  }
};
/**
 * get cities list from admin vendor edit
 */
export async function getCitiesListForVendor(stateUid) {
  let data = []
  const snapshot = await getDocs(
    query(
      collection(db, "Cities"),
      // ...(stateUid ? [where("stateId", "==", stateUid)] : []),
      where("deleted", "==", false),where("status", "==", "active"),
      where("isDefault", "==", true)
    ),
  )
    .catch((e) => {
      throw new AppError(
        AppError.DATA_FETCH_FAILED,
        "Failed to fetch cities.",
      );
    });
    if (snapshot) {
      snapshot.forEach((qs) => {
        data.push({ value: qs.id, label: qs.data().name });
      })
    }
  return data;
}
/**
 * get cities list
 * @param {string} state
 * @return {Array<any>}
 */
export function getCities(state) {
  const f = Object.keys(cities)
    .find((v) => v.toLocaleLowerCase() === state?.toLocaleLowerCase());
  return cities[f] || [];
}

/**
 * add new city
 * @param {any} data
 */
export async function createCity(data) {
  await addDoc(
    collection(db, "Cities"),
    {
      ...data,
      shortName: data?.shortName || "",
      createdAt: serverTimestamp(),
      deleted: false,
      status: "active",
    },
  )
    .catch((e) => {
      throw new AppError(
        AppError.DATA_UPDATE_FAILED,
        "Failed update/add a city.",
      );
    });
}

/**
 * update city
 * @param {any} data
 */
export async function updateCity(data) {
  await updateDoc(
    doc(db, "Cities", data?.uid),
    _.omit(data, ["uid"]),
  )
    .catch((e) => {
      throw new AppError(
        AppError.DATA_UPDATE_FAILED,
        "Failed update/add a city.",
      );
    });
}

/**
 * update city
 * @param {any} uid
 */
export async function deleteCity(uid) {
  await deleteDoc(
    doc(db, "Cities", uid),
  )
    .catch((e) => {
      throw new AppError(
        AppError.DATA_DELETE_FAILED,
        "Failed delete a city.",
      );
    });
}

/**
 * validator to check if city exists
 * @param {string} stateId
 * @param {string} cityName
 * @param {?string} shortName
 * @return {Promise<boolean>}
 */
export async function cityExists(stateId, cityName, shortName) {
  const constraints = [];

  if (shortName) {
    constraints.push(
      where("shortName", "==", shortName),
    );
  }

  if (cityName) {
    constraints.push(
      where("name", "==", cityName),
    );
  }

  if (stateId) {
    constraints.push(
      where("stateId", "==", stateId),
    );
  }

  const snapshot = await getDocs(
    query(
      collection(db, "Cities"),
      ...constraints,
    ),
  );

  return !snapshot.empty;
}

/**
 * check if city is being used by vendor or b&g
 * @param {any} city
 * @return {boolean}
 */
export async function isCityOnUse(city) {
  const vendors = await getDocs(
    query(
      collection(db, "Vendors"),
      where("cities", "array-contains", {label: city.name, value: city.uid}),
    ),
  );

  return !vendors.empty;
}

/**
 * get a state data based on id
 */
export async function getStateData(stateId) {

  const ref = doc(db, "States", stateId);
  const snapshot = await getDoc(ref);
  return snapshot.data();
}
