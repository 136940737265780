/* eslint-disable */
import React from "react";

/**
 * @return {any}
 */
export function Logo() {
  return (
    <>
      <div className="logo">
        <img alt="logo" src="./images/logo.svg"></img>
      </div>
      
    </>
  );
}
