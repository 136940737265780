import {doc, getDoc} from "firebase/firestore";
import {db} from "./firebase";

/**
 * get user by uid
 * @param {string} userUID
 * @return {any}
 */
export async function getUserFromId(userUID) {
  const user = await getDoc(
    doc(db, "Users", userUID),
  );

  return user.data();
}
