import OneSignal from 'react-onesignal';
import { addplayerId, getCurrentUserId, deletePlayerID, Platform } from './notification';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

/**
 * Initialize Onesignal
 */
export function useOnesignalInit() {
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        if (initialized) return;

        OneSignal.init({
            appId: process.env.REACT_APP_ONESIGNAL_APPID,
            safari_web_id: process.env.REACT_APP_ONESIGNAL_SAFARIWEBID,
            notifyButton: {
                enable: true,
            },
            allowLocalhostAsSecureOrigin: true
        })
            .then(() => {
                setInitialized(true);
                OneSignal.showSlidedownPrompt();
            })
            .finally(() => setInitialized(true));
    }, [initialized]);
}

/**
 * get playerid
 */
export function getPlayerId() {
    return OneSignal.getUserId();
}

/**
 * unsubscribe from notification service
 */
export async function unsubscribe() {
    const [userId, playerId] = await Promise.all([getCurrentUserId(), getPlayerId()]);
    await deletePlayerID(Platform.ADMIN, userId, playerId);
}

/**
 * subscribe from notification service
 */
async function subscribe(userId) {
    if (!userId) return;
    const playerId = await getPlayerId();

    addplayerId(Platform.ADMIN, userId, playerId)
        .catch(console.error);
}

/**
 * add player id auto.
 */
export function useOnesignalSubscription() {
    const user = useSelector(({ user }) => user?.user);

    useEffect(() => {
        if (!user?.uid) return;

        subscribe(user.uid).catch(console.error);

        function subChangeHandler(isSubscribed) {
            if (isSubscribed) {
                subscribe()
                    .catch(console.error);
            }
        }

        OneSignal.on('subscriptionChange', subChangeHandler);

        return () => OneSignal.off('subscriptionChange', subChangeHandler);

    }, [user]);
}