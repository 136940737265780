import React, { lazy } from "react";
import { Route } from "react-router-dom";
import PermissionComponent from "./PermissionComponent";

const routes = [
  {
    path: "/master-modules",
    component: lazy(() => import("../pages/master-modules/Index")),
  },
  {
    component: lazy(() => import("../pages/master-modules/Cities")),
    path: "/master-modules/cities",
  },
  {
    component: lazy(() => import("../pages/master-modules/T&C/TCLanding")),
    path: "/master-modules/terms-and-conditions",
  },
  {
    path: "/master-modules/styles",
    component: lazy(() => import("../pages/master-modules/MasterStyles")),
  },
  {
    path: "/master-modules/religions",
    component: lazy(() => import("../pages/master-modules/Religion")),
  },
  {
    component: lazy(() => import("../pages/master-modules/SubReligion")),
    path: "/master-modules/religions/:religionId",
  },
  {
    path: "/master-modules/vendors-faq",
    component: lazy(() => import("../pages/master-modules/Faq/Faq")),
  },
  {
    component: lazy(() => import("../pages/master-modules/Todos/Todos")),
    path: "/master-modules/Todos",
  },
  {
    component: lazy(() => import("../pages/master-modules/Vendort&c/vendor-contracttnc")),
    path: "/master-modules/vendor-contracttnc",
  },
  {
    path: "/master-modules/categories",
    component: lazy(() => import("../pages/master-modules/categories/VendorCategories")),
  },
  {
    component: lazy(() => import("../pages/master-modules/categories/AddCategory")),
    path: "/master-modules/categories/AddCategory",
  },
  {
    path: "/master-modules/categories/EditCategory",
    component: lazy(() => import("../pages/master-modules/categories/EditCategory")),
  },
  {
    path: "/master-modules/templates",
    component: lazy(() => import("../pages/master-modules/Templates/Templates")),
  },
  {
    path: "/master-modules/templates/AddTemplates",
    component: lazy(() => import("../pages/master-modules/Templates/AddTemplates")),
  },
  {
    component: lazy(() => import("../pages/master-modules/Templates/EditTemplates")),
    path: "/master-modules/templates/EditTemplates",
  },
  {
    path: "templates/EditTemplates",
    component: lazy(() => import("../pages/master-modules/Templates/EditTemplates")),
  },
  {
    component: lazy(() => import("../pages/master-modules/TodoEmails/TodoEmailsListing")),
    path: "/master-modules/planning-emails",
  },
  {
    component: lazy(() => import("../pages/master-modules/TodoEmails/AddEmails")),
    path: "/master-modules/planning-emails/add",
  },
  {
    component: lazy(() => import("../pages/master-modules/TodoEmails/EditEmail")),
    path: "/master-modules/planning-emails/:id/edit",
  },
  {
    path: "/master-modules/guest-criteria",
    component: lazy(() => import("../pages/master-modules/guestCriteria/guestCriteria")),
  },
  {
    path: "/master-modules/payment-remider-days",
    component: lazy(() => import("../pages/master-modules/NotificationRemiderDays/notificationRemiderDays")),
  },
];

export const masterModuleRoutes = () => {
  return (
    <React.Fragment>
      {routes.map((route, i) => {
        return (
          <Route
            key={i}
            path={route.path}
            element={<PermissionComponent
              permission={route.permission}
              element={route.component} />} />
        );
      })}
    </React.Fragment>
  );
};
