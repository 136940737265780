/* eslint-disable */
import React, { lazy } from "react";
import { Route } from "react-router-dom";
import PermissionComponent from "./PermissionComponent";

const routes = [
  {
    component: lazy(() => import("../pages/Dashboard")),
    path: "/",
  },
  {
    component: lazy(() => import("../pages/my-schedule/MySchedule")),
    path: "/schedules",
  },
  {
    component: lazy(() => import("../pages/chat-reports/FlaggedMessages")),
    path: "/chat-reports",
  },
  {
    component: lazy(() => import("../pages/admin/AdminProfile")),
    path: "/profile",
  },
  {
    component: lazy(() => import("../pages/admin/EditAdmin")),
    path: "/editadminprofile",
  },
  {
    component: lazy(() => import("../pages/users/UsersList")),
    path: "/users",
  },
  {
    component: lazy(() => import("../pages/vendors/VendorsListing")),
    permission: {
      dashboard: {
        vendors: true,
      },
    },
    path: "/vendors/:status",
  },
  {
    component: lazy(() => import("../pages/users/UserToDos")),
    path: "/usertodo",
  },
  {
    component: lazy(() => import("../pages/sub-admin/SubadminListing")),
    path: "/sub-admins",
    permission: {
      subAdmins: undefined,
    },
  },
  {
    component: lazy(() => import("../pages/users/UserSurveyResult")),
    path: "/usersurveyresult",
  },
  {
    path: "/usertodods",
    component: lazy(() => import("../pages/users/UserToDos")),
  },
  {
    path: "/contracts/:status",
    component: lazy(() => import("../pages/contracts/ContractsListing")),
  },
  {
    path: "/contracts",
    component: lazy(() => import("../pages/contracts/ContractsListing")),
  },
  {
    permission: {
      subAdmins: {
        add: true,
      },
    },
    component: lazy(() => import("../pages/sub-admin/EditSubadmin")),
    path: "/editsubadmin",
  },
  {
    component: lazy(() => import("../pages/payments/PaymentsListing")),
    path: "/payments",
  },
  {
    path: "/questionaires",
    component: lazy(() => import("../pages/questionaires/QuestionairesListing")),
  },
  {
    path: "/addquestions",
    component: lazy(() => import("../pages/questionaires/AddQuestions")),
  },
  {
    path: "/vendors",
    permission: {
      dashboard: {
        vendors: true,
      },
    },
    component: lazy(() => import("../pages/vendors/VendorsListing")),
  },

  {
    component: lazy(() => import("../pages/master-modules/Cities")),
    path: "/cities",
  },
  {
    component: lazy(() => import("../pages/master-modules/MasterStyles")),
    path: "/styles",
  },
  {
    component: lazy(() => import("../pages/master-modules/Religion")),
    path: "/religion",
  },
  {
    component: lazy(() => import("../pages/master-modules/Templates/Templates")),
    path: "/templates",
  },
  {
    component: lazy(() => import("../pages/master-modules/Templates/AddTemplates")),
    path: "templates/AddTemplates",
  },
  // {
  //   component: lazy(() => import("../pages/master-modules/ToDos")),
  //   path: "/todos",
  // },
  // {
  //   component: lazy(() => import("../pages/master-modules/FAQ")),
  //   path: "/faq",
  // },
  {
    component: lazy(() => import("../pages/master-modules/T&C/TCLanding")),
    path: "/tandc",
  },
  {
    component: lazy(() => import("../pages/master-modules/categories/EditCategory")),
    path: "/categories/EditCategory",
  },
  {
    component: lazy(() => import("../pages/master-modules/categories/AddCategory")),
    path: "/categories/AddCategory",
  },
  {
    component: lazy(() => import("../pages/master-modules/categories/VendorCategories")),
    path: "/categories",
  },
  {
    component: lazy(() => import("../pages/master-modules/Vendort&c/vendor-contracttnc")),
    path: "/vendor-contracttnc",
  },
  {
    component: lazy(() => import("../pages/sub-admin/CreateSubadmin")),
    permission: {
      subAdmins: {
        add: true,
      },
    },
    path: "/createsubadmin",
  },
  {
    component: lazy(() => import("../pages/blog/BlogListing")),
    path: "/blogs",
  },
  {
    component: lazy(() => import("../pages/blog/BlogDetails")),
    path: "/blogdetails",
  },
  {
    component: lazy(() => import("../pages/blog/CreateBlog")),
    path: "/createblog",
  },
  {
    path: "/subadminlisting",
    permission: {
      subAdmins: undefined,
    },
    component: lazy(() => import("../pages/sub-admin/SubadminListing")),
  },
  {
    path: "/references",
    component: lazy(() => import("../pages/References/ReferenceListing")),
  },
  {
    path: "/VendorContracts",
    component: lazy(() => import("../pages/VendorContracts/VendorContractsListing")),
  }
];

export const dashboardRoutes = () => {
  return (
    <React.Fragment>
      {routes.map((route, i) => {
        return (
          <Route
            key={i} path={route.path}
            element={<PermissionComponent
              permission={route.permission}
              element={route.component} />} />
        );
      })}
    </React.Fragment>
  );
};
