/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from "react";
import { Outlet, useLocation, useParams, useNavigate } from "react-router-dom";
import { BrideAndGroomCard, WeddingDetail } from "../../pages/vendors/vendor-proposals/VendorProposalDetails";
import { getMainEvent,getBudgetSplitTotalValue ,getSumInvoiceAmount} from "../../services/mainEvents";
import { getUserFromId } from "../../services/users";
import { useAsyncTask } from "../../utils";
import { Loader } from "../common/Loader";
import PageNamecard from "../PageNameCard";

const menu = [
  {title: "Events", path: "events"},
  // {title: "Proposals", path: "proposals"},
  // {title: "Contracts", path: "contracts"},
  // {title: "To Dos", path: "usertodos"},
  // {title: "Survey Results", path: "Survey Results"},
  {title: "Meeting Schedule", path: "schedules"},
  {title: "Preferences", path: "userpreferences"}
];

/**
 *
 * @param {any} param0
 * @return {any}
 */
export function UserViewLayout() {
  const { pathname } = useLocation();
  const { id: mainEventId } = useParams();
  let navigate = useNavigate();

  const [fetchData, { data, isLoading }] = useAsyncTask(async () => {
    const mainEvent = await getMainEvent(mainEventId);
    const budgetSplitTotalValue=await getBudgetSplitTotalValue(mainEventId)
    const sumInvoiceAmount=await getSumInvoiceAmount(mainEventId)
    const bride = await getUserFromId(mainEvent.brideId).catch(() => undefined);
    const groom = await getUserFromId(mainEvent.groomId).catch(() => undefined);
    return { bride, groom, mainEvent ,budgetSplitTotalValue,sumInvoiceAmount};
  });

  const newPageFun = (path) => {
    const newPath = "users/" + mainEventId + "/" + path;
    navigate(newPath);
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Loader spinning={isLoading}></Loader>
      <PageNamecard title="Users"></PageNamecard>

      <div className="profileCard">
        <div className="row">
          <div className="col-12 col-lg-4 col-xl-3">
            <div className="profileCard-left">
              <BrideAndGroomCard
                showAddress={true}
                data={{ bride: data?.bride, groom: data?.groom }} />

            </div>
          </div>
          <div className="col-12 col-lg-8 col-xl-9">
            <div className="profileCard-right">
              <div className="userProfileContent">

                <div className="profileListDetails">
                  <WeddingDetail data={data} />
                </div>
                <div className="detailedContent">
                  <div className="tabList">
                    <ul>
                      {menu.map(
                        ({ title, path }) => <li
                          key={path}
                          onClick={() => newPageFun(path)}
                          className={pathname.endsWith(path) ?
                            "active" :
                            undefined}>
                          {title}
                        </li>)}
                    </ul>
                  </div>
                  <Outlet />
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
}
