import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  cityList: [],
};
export const citySlice = createSlice({
  name: "citySlice",
  initialState,
  reducers: {
    setCities: (state, action) => {
      let { payload } = action;
      state.cityList = payload?.cities || [];
    },
  },
});
export const { setStates, setCities } = citySlice.actions;
export default citySlice.reducer;