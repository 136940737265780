/* eslint-disable */
import React, { useState } from 'react';
import { Button, Modal } from "antd";
import { formatToTableDateFormat, joinAndSkipNull } from '../../utils';
import { cellPhone } from '../../config';

const _iconMap = {
  "facebook": "/images/sc1.svg",
  "twitter": "/images/sc2.svg",
  "instagram": "/images/sc3.png",
  "snapchat": "/images/sc4.svg",
  "website": "/images/sc5.svg",
  "spotify": "/images/sc6.svg",
  "youtube": "/images/sc7.png",
  "pinterest": "/images/sc8.svg",
};

/**
 * @return {any}
 */
export function UserCard({ data, showAddress }) {

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const socialIcons = Object
    .entries(data?.socialUrls || {})
    .filter(([_, value]) => value)
    .map(([key, value], i) => {
      const icon = _iconMap[key];
      return (
        <li key={i}>
          <a href={value} target="_blank" rel="noreferrer">
            <img alt={key} src={icon}></img>
          </a>
        </li>
      );
    });

  return (
    <>
      <div className="userCard">
        <figure>
          <img src={data?.photoUrls || "/images/dummy-user.png"}></img>
        </figure>
        <h5>{data?.firstName} {data?.lastName}</h5>
        <h6>{data?.brideGroom}</h6>
        {showAddress && <p>{
          joinAndSkipNull([
            data?.address1,
            data?.address2,
            data?.city,
            data?.state,
            data?.zipCode
          ],
            ", ")}</p>}
        <Button className="outlineBtn" onClick={showModal}>Get Contact</Button>
      </div>

      <Modal
        closable={true}
        data={data}
        title="Get contact"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        className="cmnModal getcontactModal"
        footer={[]}
      >
        <div className="row">
          <div className='col-12 col-md-6'>
            <label>Name</label>
            <p>{data?.firstName} {data?.lastName}</p>
          </div>
          <div className='col-12 col-md-6'>
            <label>{cellPhone}</label>
            <p>+1 {data?.phone || "No data"}</p>
          </div>
          <div className='col-12 col-md-6'>
            <label>Email</label>
            <p>{data?.email}</p>
          </div>
          <div className='col-12 col-md-6'>
            <label>Registered Date</label>
            <p>{formatToTableDateFormat(data?.createdAt)}</p>
          </div>
          <div className='col-12 col-md-6'>
            <label>Address 1</label>
            <p>{data?.address1}</p>
          </div>
          <div className='col-12 col-md-6'>
            <label>Address 2</label>
            <p>{data?.address2}</p>
          </div>
          <div className='col-12 col-md-6'>
            <label>City</label>
            <p>{data?.city}</p>
          </div>
          <div className='col-12 col-md-6'>
            <label>State</label>
            <p>{data?.state}</p>
          </div>
          <div className='col-12 col-md-6'>
            <label>Zipcode</label>
            <p>{data?.zipCode}</p>
          </div>
        </div>
      </Modal>
    </>
  );
}
