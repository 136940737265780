/* eslint-disable */
import { Dropdown, Menu } from "antd";
import { React } from "react";
import LogoutIcon from "./icons/Logouticon";
import ProfileIcon from "./icons/Profileicon";
import { CaretDownOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { userLogout } from "../store/auth/thunks";
import Notifications from "./notifications/Notifications";
import { DEFAULT_PROFILE_PLACEHOLDER } from "../config";
import { useNavigate } from "react-router-dom";
import { selectUser } from "../store/auth/user";
import { unsubscribe } from "../services/onesignal";

/**
 * Profile menu
 * @return {any}
 */
function ProfileMenu() {
  const dispatch = useDispatch();
  const nav = useNavigate();


  const onMenuClick = async ({ key }) => {
    if (key === "logOut") {
      await unsubscribe();
      dispatch(userLogout());
    } else if (key === "profile") {
      nav("/profile");
    } else if (key === "chat") {
      window.open("https://dashboard.getstream.io/", "_blank");
    }
  };

  let menu = [];

  menu = [
    {
      icon: <ProfileIcon />,
      label: "My Profile",
      key: "profile"
    },
    {
      icon: <img src="/images/vendorcontractsicon.svg" />,
      label: "View Chats",
      key: "chat"
    },
    {
      icon: <LogoutIcon />,
      label: "Logout",
      key: "logOut"
    }
  ];

  return (
    <Menu onClick={onMenuClick} items={menu}>
    </Menu>
  );
}

/**
 * Dashboard header
 * @return {any}
 */
export function DashboardHeader() {
  const user = useSelector(selectUser);

  return (
    <>
      <header>
        <div className="container-wrp">
          <div className="row align-items-center justify-content-between">
            <div className="col-auto">
              <div className="dashboardlogo">
                <img alt="logo" src="/images/dashboard-logo.svg"></img>
              </div>
            </div>
            <div className="col-auto ">
              <div className="headericons-grp">
                <ul className="list-unstyled">

                  <li>
                    <Notifications />
                  </li>

                  <li>
                    <div className="headerUser">
                      <figure>
                        <img alt="user"
                          src={user?.photoUrl || DEFAULT_PROFILE_PLACEHOLDER}>
                        </img>
                      </figure>
                      <div >
                        <Dropdown
                          overlay={<ProfileMenu />}
                          className="dropdown-wrp"
                          placement="bottomRight">
                          <div
                            className="ant-dropdown-link">
                            {user?.name || "Admin"}&nbsp;
                            <CaretDownOutlined />
                          </div>
                        </Dropdown>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
