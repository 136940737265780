import {collection, getDocs, query, where} from "firebase/firestore";
import _ from "lodash";
import {getSubCollection} from "../utils";
import {db} from "./firebase";

/**
 * @param {string} email
 * @return {*}
 */
export async function loadAdminByEmail(email) {
  const snapshot = (await getDocs(
    query(
      collection(db, "Admins"),
      where("email", "==", email),
    ),
  )
  );

  const admin = _.first(snapshot.docs);
  const permissions = await getSubCollection(admin.ref, "Permissions")
    .catch(() => ({}));

  return {
    ...admin?.data(),
    permissions,
    uid: admin.id,
  };
}
