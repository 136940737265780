import { getWordCount } from "./regex";

const {
  REACT_APP_BASE_URL,
  REACT_APP_API_BASE_URL,
  REACT_APP_RECAPTCHA_PUBLIC_KEY,
  REACT_APP_CALENDLY_API_CANCEL_URL,
  REACT_APP_CHAT_API_ADD_USER,
  REACT_APP_CHAT_API_FLAG_LISTING
} = process.env;

export const baseUrl = REACT_APP_BASE_URL;
export const baseAPIUrl = REACT_APP_API_BASE_URL;
export const calendlyAPICancelUrl = REACT_APP_CALENDLY_API_CANCEL_URL;
export const chatApiAddUserUrl = REACT_APP_CHAT_API_ADD_USER;
export const chatApiFlaggedListingUrl = REACT_APP_CHAT_API_FLAG_LISTING;
export const recaptchaPublicKey = REACT_APP_RECAPTCHA_PUBLIC_KEY;
export const DEFAULT_PROFILE_PLACEHOLDER = "/images/dummy-user.png";
export const NO_AVATAR_PLACEHOLDER = "/images/placeholder-img.png";

export const PlanTypes = {
  free: 'free',
  paid: 'paid',
}

export const ConciergeCallsTypes = {
  limited: 'limited',
  unlimited: 'unlimited',
}

export const PlanBelongs = {
  withinSixMonths: 'withinSixMonths',
  afterSixMonths: 'afterSixMonths',
}

export const Collections = {
  SystemConfig: 'SystemConfig',
  SubscriptionPlans: 'SubscriptionPlans',
}

export const cellPhone = 'Cell phone Number'

/**
 * Validates the word count of a string
 * @param {string} value text to validate
 * @param {number} min minimum word count
 * @param {number} max maximum word count
 * @param {string} text name of the input field
 * @return {Promise<any>}
 */
export const validateWordCount = (value, min, max, text) => {
  const count = getWordCount(value);
  if (count < min || count > max) {
    return Promise.reject(
      `${text} should have ${min}-${max} words.`
    );
  }

  return Promise.resolve();
};
