/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Button, Tooltip } from "antd";
import React from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import FileEdit from "../icons/FileEdit";
import PageNamecard from "../PageNameCard";

/**
 *
 * @param {any} param0
 * @return {any}
 */
export function MasterViewLayout() {
  return (
    <>
      <PageNamecard title="Master module"></PageNamecard>
      <Navigation />
      <Outlet />
    </>
  );
}
let items = [];
if (process.env.REACT_APP_ENV !== "prod" && process.env.REACT_APP_ENV !== "uat") {
  items = [
    {
      path: "Todos",
      title: "To-Dos",
    },
    {
      path: "cities",
      title: "Cities",
    },
    {
      title: "Styles",
      path: "styles",
    },
    {
      path: "religions",
      title: "Religion",
    },
    {
      title: "T & C",
      path: "terms-and-conditions",
    },
    {
      path: "vendors-faq",
      title: "Vendors FAQ",
    },
    {
      title: "Templates",
      path: "templates",
    },
    {
      path: "planning-emails",
      title: "Planning emails",
    },
    {
      title: "Categories",
      path: "categories",
    },
    {
      path: "guest-criteria",
      title: "Guest Criteria",
    },
    {
      path: "payment-remider-days",
      title: "Notification",
    },
  ];
} else {
  items = [
    {
      path: "Todos",
      title: "To-Dos",
    },
    {
      path: "religions",
      title: "Religion",
    },
    {
      title: "T & C",
      path: "terms-and-conditions",
    },
    {
      title: "Vendors FAQ",
      path: "vendors-faq",
    },
    {
      title: "Categories",
      path: "categories",
    },
    {
      title: "Templates",
      path: "templates",
    },
    {
      path: "planning-emails",
      title: "Planning emails",
    },
    {
      path: "guest-criteria",
      title: "Guest Criteria",
    },
    {
      path: "payment-remider-days",
      title: "Notification",
    },
  ];
}


/**
 * navigation list based on vendor status
 * @return {any}
 */
function Navigation() {
  const { pathname } = useLocation();
  const nav = useNavigate();
  const openProfileSetup = () => {
    nav('/master-modules/vendor-contracttnc')
  }
  const menu = items
    .map(({ title, path }, i) => {
      const route = `/master-modules/${path}`;
      const active = pathname.startsWith(route) ?
        "active" :
        undefined;
      return (<li
        className={active}
        onClick={() => nav(route)}
        key={i}>
        {title}
      </li>);
    });

  function ContractButton() {
    const { pathname } = useLocation();
    const active = pathname == '/master-modules/vendor-contracttnc' ?
      "active iconBtn" :
      "iconBtn";
    return (
      <>
        <Button className={active} onClick={openProfileSetup}>
          <FileEdit />
        </Button>
      </>
    );
  }
  return (
    <div className="tabList">
      <ul>
        {menu}
      </ul>
      {process.env.REACT_APP_ENV !== "prod" && process.env.REACT_APP_ENV !== "uat" &&
        (<Tooltip title="Vendor Contract Form" arrowPointAtCenter="true" placement="topRight" >
          <ContractButton />
        </Tooltip>)
      }
    </div>
  );
}
