import {createSlice} from "@reduxjs/toolkit";
import {useSelector} from "react-redux";
import {loadUserData, userLogin} from "./thunks";

const userSlice = createSlice({
  name: "user",
  /**
   * @type {{
   *  user: any
   * }}
   */
  initialState: {
    user: {},
  },
  reducers: {
    reset: (state) => {
      state = {loading: undefined, error: undefined, user: {}};
    },
    init: () => { },
  },
  extraReducers: (builder) => {
    builder
      .addCase(userLogin.pending, (state, action) => {
        state.loading = true;
        state.error = undefined;
      })
      .addCase(userLogin.fulfilled, (state, action) => {
        state.error = undefined;
        state.loading = false;
        state.user = {...state.user, ...action.payload};
      })
      .addCase(userLogin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      })
      .addCase(loadUserData.fulfilled, (state, action) => {
        state.loading = false;
        state.user = {...state.user, ...action.payload};
      })
      .addCase(loadUserData.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(loadUserData.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const useUserLogin = () => {
  const user = useSelector(({user}) => user);
  return [userLogin, user];
};

export const selectUser = (state) => state?.user?.user;

const {reducer, actions} = userSlice;
export const {reset, init} = actions;
export default reducer;
