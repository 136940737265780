/* eslint-disable */
import React from "react";

/**
 * Logout icon
 * @return {any}
 */
export default function FileEdit() {
    return (
        <>

            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                <defs>
                    <clipPath id="clip-path">
                        <rect id="Rectangle_9115" data-name="Rectangle 9115" width="16" height="16" transform="translate(47 313)" fill="#fff" stroke="#fff" strokeWidth="1" />
                    </clipPath>
                </defs>
                <g id="Mask_Group_1369" data-name="Mask Group 1369" transform="translate(-47 -313)" clipPath="url(#clip-path)">
                    <path id="Path_41488" data-name="Path 41488" d="M129.658,67.193a.392.392,0,0,0-.236.109l-3.3,3.278a.393.393,0,0,0-.115.273V82.319a.4.4,0,0,0,.388.388h10.862a.4.4,0,0,0,.388-.388V77.083c0-.012,0-.024,0-.036l3.57-3.569a1.079,1.079,0,0,0,.279-.436.841.841,0,0,0,.024-.285.391.391,0,0,0-.109-.218l-.776-.776a.391.391,0,0,0-.194-.1.624.624,0,0,0-.115-.012.723.723,0,0,0-.182.03,1.057,1.057,0,0,0-.442.279L137.64,74.01v-6.43a.4.4,0,0,0-.388-.388H134.5a.388.388,0,1,0,.036.776h2.328v6.818l-2.3,2.3-.024-.006H129.07a.388.388,0,1,0,.036.776h4.685l-.885.885a.39.39,0,0,0-.1.17l-.388,1.357a.409.409,0,0,0,.485.479l1.358-.388a.389.389,0,0,0,.164-.1l2.443-2.442v4.109H126.778V71.653h3.3a.4.4,0,0,0,.388-.388v-3.3h2.521a.388.388,0,1,0,0-.776h-3.328Zm.03.939v2.745h-2.776Zm-.618,4.3a.388.388,0,1,0,.036.776h5.625a.388.388,0,1,0,0-.776H129.07Zm11.189.061.418.418c0,.007,0,.007-.012.018l-6.716,6.714-.588.17.17-.594,6.728-6.727Zm-10.219,2.266a.388.388,0,1,0,.036.776h4.655a.388.388,0,1,0,0-.776h-4.691Z" transform="translate(-79.003 245.807)" fill="#5f717a" />
                </g>
            </svg>


        </>
    );
}
