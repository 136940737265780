import {
  browserLocalPersistence,
  browserSessionPersistence,
  confirmPasswordReset,
  setPersistence,
  signInWithEmailAndPassword,
  signOut,
  verifyPasswordResetCode,
} from "firebase/auth";
import {AppError} from "./AppError";
import {auth} from "./firebase";

/**
 * @return {any}
 */
export function getUser() {
  return auth.currentUser;
}

/**
 * @return {any}
 */
export function getidtoken() {
  return auth.currentUser.getIdToken();
}
/**
 * Login using email and password
 *
 * @param {string} email
 * @param {string} password
 * @param {boolean} rememberMe
 * @return {Promise<any>}
 */
export async function login(email, password, rememberMe) {
  const response = await signInWithEmailAndPassword(auth, email, password)
    .catch((e) => {
      throw new AppError(
        AppError.USER_LOGIN_ERROR,
        _mapFirebaseErrorToApp(e.code),
      );
    });

  await _setPersistance(rememberMe);

  return response;
}

/**
 * wraps signOut and cleanup
 * @return {Promise<void>}
 */
export function logout() {
  return signOut(auth);
}

/**
 *
 * @param {string} token
 * @return {Promise<boolean>}
 */
export function verifyPasswordResetToken(token) {
  return verifyPasswordResetCode(auth, token);
}

/**
 * Reset password
 * @param {string} token
 * @param {string} password
 * @return {Promise<void>}
 */
export function resetPasswordUsingToken(token, password) {
  return confirmPasswordReset(auth, token, password).catch((e) => {
    throw new AppError(
      AppError.USER_PASSWORD_RESET_FAILED,
      _resetFirebaseErrorMap(e.code),
    );
  });
}

/**
 *
 * @param {boolean} rememberMe
 * @return {void}
 */
function _setPersistance(rememberMe) {
  let type = browserSessionPersistence;

  if (rememberMe) {
    type = browserLocalPersistence;
  }

  return setPersistence(auth, type);
}

/**
 *
 * @param {any} user
 * @return {boolean}
 */
export function isAdmin(user) {
  const data = user?.reloadUserInfo?.customAttributes;
  const claims = typeof data === "string" ?
    _parseAttrs(data) :
    (data || {});
  return claims?.superAdmin ||
    claims?.admin;
}

/**
 *
 * @param {string} code
 * @return {string}
 */
function _resetFirebaseErrorMap(code) {
  let m;
  switch (code) {
  case "auth/missing-email":
  case "auth/user-not-found":
    m = "Email does not exist in our system, Please check with your admin";
    break;
  case "auth/expired-action-code":
    m = "Verification token is expired";
    break;
  default:
    m = "Something went wrong try again later";
  }

  return m;
}

/**
 * map error codes to messages
 * @param {string} code
 * @return {string}
 */
function _mapFirebaseErrorToApp(code) {
  let m;

  switch (code) {
  case "auth/user-disabled":
    m = "Your account is disabled. Please contact administrator to enable it";
    break;
  case "auth/user-not-found":
    m = "The account does not exist." +
    " Enter a valid email id or contact Desiweds admin";
    break;
  case "auth/wrong-password":
    m = "Your email address or password is" +
    " incorrect. Please check and try again.";
    break;
  case "unathorizeduser":
    m = "Your account is disabled. Please" +
        "contact the administrator to enable it";
    break;
  case "notadmin":
    m = "User not found";
    break;
  default:
    m = "Something went wrong try again later";
  }

  return m;
}

/**
     *
     * @param {any} customAttributes
     * @return {any}
     */
function _parseAttrs(customAttributes) {
  try {
    return JSON.parse(customAttributes);
  } catch (error) {
    return {};
  }
}
