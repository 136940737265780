import React, {lazy} from "react";
import {Route} from "react-router-dom";

const routes = [
  {
    path: "/login",
    component: lazy(() => import("../pages/auth/SignIn")),
  },
  {
    path: "/forgot-password",
    component: lazy(() => import("../pages/auth/ForgotPassword")),
  },
  {
    path: "/change-password",
    component: lazy(() => import("../pages/auth/ChangePassword")),
  },
  {
    path: "/verify-reset",
    component: lazy(() => import("../pages/auth/VerifyResetCode")),
  },
];

export {routes};

export const authRoutes = () => {
  return (
    <React.Fragment>
      {routes.map((route, i) => {
        return (
          <Route key={i} path={route.path} element={<route.component />} />
        );
      })}
    </React.Fragment>
  );
};
