/* eslint-disable no-unused-vars */
import {
  collection,
  collectionGroup,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
  where,setDoc
} from "firebase/firestore";
import _ from "lodash";
import { client } from "../api";
import { getSubCollections } from "../utils";
import { AppError } from "./AppError";
import { auth, db } from "./firebase";
import { addClaim, deleteClaim } from "../api/admin/auth";
import { VENDOR_CLAIM } from "../config/claims";
import { addUser } from "./chat";
import { getStateData } from "./states";
import { getReligionById } from "./religion";
/**
 * used for status identification
 */
export const STATUSES = {
  PENDING: "Pending",
  VERIFIED: "Verified",
  DECLINED: "Declined",
};

export const STATUSES_DB_VALUES = {
  [STATUSES.PENDING]: "pending",
  [STATUSES.DECLINED]: "rejected",
  [STATUSES.VERIFIED]: "verified",
};

/**
 * Get vendor list from status
 * @param {string} status
 * @return {Promise<Array<any>>}
 */
export async function getVendorsByStatus(status) {
  const snapshot = await getDocs(
    query(
      collection(db, "Vendors"),
      _orderByStatusConstraints(status),
      ..._filterOutStatus(status),
    ),
  )
    .catch((e) => {
      throw new AppError(
        AppError.DATA_FETCH_FAILED,
        "Failed to fetch data.",
      );
    });

  return snapshot.docs.map((s) => ({
    ...s.data(),
    uid: s.id,
  }));
}

/**
 * Fetch vendor categories
 */
export async function getVendorCategories() {
  let data = []
  const snapshot = await getDocs(
    query(
      collection(db, "VendorCategories"),
      where("status", "==", "active"),
      where("deleted", "==", false)
    ),
  )
    .catch((e) => {
      throw new AppError(
        AppError.DATA_FETCH_FAILED,
        "Failed to fetch data.",
      );
    });
    if (snapshot) {
      snapshot.forEach((qs) => {
        data.push({ value: qs.id, label: qs.data().name });
      })
    }
    return (data);
}


/**
 * Fetch vendor categories
 * @param {string} vendorUID
 * @param {string} status
 * @param {string} reason
 * @param {email} vendorEmail
 * @return {Promise<any>}
 */
export async function setVendorVerificationStatus(
  vendorUID,
  status,
  reason,
  vendorEmail,
) {
  const vendorRef = doc(db, "Vendors", vendorUID);
  const _status = STATUSES_DB_VALUES[status];

  await updateDoc(vendorRef, {
    status: _status || status,
    ...(status === STATUSES.DECLINED && {
      declinedReason: reason,
      declinedAt: serverTimestamp(),
      declinedBy: auth?.currentUser.email,
    }),
  });

  if (status === STATUSES.VERIFIED) {
    addUser(vendorEmail);
    await addClaim(vendorEmail, VENDOR_CLAIM);
  }

  if (status === "disabled" || status === STATUSES.DECLINED) {
    await deleteClaim(vendorEmail, VENDOR_CLAIM);
  }

  if (status === STATUSES.DECLINED) {
    addUser(vendorEmail);
    await _sendDeclinedRequestEmail(vendorUID, reason);
  }

  if (status === STATUSES.VERIFIED) {
    await _sendVerificationRequestEmail(vendorUID);
  }
}

/**
 * send disabled email
 * @param {string} vendorUID
 * @param {string} reason
 * @return {any}
 */
export function sendDisabledEmail(vendorUID) {
  return client.post(
    `/vendor/${vendorUID}/sendDisabledEmail`,
  );
}

/**
 * send enabled email
 * @param {string} vendorUID
 * @param {string} reason
 * @return {any}
 */
export function sendEnabledEmail(vendorUID) {
  return client.post(
    `/vendor/${vendorUID}/sendEnabledEmail`,
  );
}

/**
 * get vendor by id
 *
 * @param {string} vendorUID
 * @param {Array<string>} subCollections
 */
export async function getVendorById(vendorUID, subCollections = []) {
  const ref = doc(db, "Vendors", vendorUID);
  const snapshot = await getDoc(ref);

  const expertisedReligionArray = snapshot.data()?.expertisedReligion;

  const expertisedReligionLabel = (Array.isArray(expertisedReligionArray) && expertisedReligionArray?.length >0 )
    ? expertisedReligionArray.map(item => item.label).join(',')
    : '';
  
  let religion;

  if (expertisedReligionLabel) {
    religion = { name: expertisedReligionLabel };
  } else if(snapshot.data()?.expertisedReligion?.length===0) {
    religion={ name: 'no data' }
  }
  else
  {
    religion = snapshot.data()?.expertisedReligion  ? await getReligionById(snapshot.data()?.expertisedReligion): { name: 'no data' };
  }

  console.log("religion",religion);
  const d = {};

  await Promise.all(
    subCollections.map(async (c) => {
      const data = await getSubCollections(ref, c);
      d[_.camelCase(c)] = data;
    }),
  );

  return {
    uid: snapshot.id,
    ...snapshot.data(),
    religion: religion || { name: 'no data' }, // Handle the case when religion is undefined
    ...d,
  };
}



/**
 * update vendor profit percentage
 * @param {string} vendorUID
 * @param {number} percentage
 */
export async function updateProfitPercentage(vendorUID, percentage) {
  const ref = doc(db, "Vendors", vendorUID);
  await updateDoc(ref, { profitPercentage: percentage });
}

/**
 * send declined email
 * @param {string} vendorUID
 * @param {string} reason
 * @return {any}
 */
function _sendDeclinedRequestEmail(vendorUID, reason) {
  return client.post(
    `/vendor/${vendorUID}/sendDeclinedRequestEmail`,
    { reason },
  );
}

/**
 * send declined email
 * @param {string} vendorUID
 * @return {any}
 */
function _sendVerificationRequestEmail(vendorUID) {
  return client.post(
    `/vendor/${vendorUID}/sendVerificationRequestEmail`,
    {},
  );
}

/**
 * send declined email
 * @param {string} vendorUID}
 * @param {string} contractUID
 * @return {any}
 */
export function sendCancelContractEmail(vendorUID, contractUID) {
  return client.post(
    `/vendor/${vendorUID}/` +
    `contracts/${contractUID}/sendVerificationRequestEmail`,
    {},
  );
}

/**
 * date filter for fetching vendors
 * @param {string} status
 * @return {any}
 */
function _orderByStatusConstraints(status) {
  // if (status === STATUSES.PENDING) {
  //   return orderBy("createdAt", "desc");
  // }

  // if (status === STATUSES.DECLINED) {
  //   return orderBy("declinedAt", "desc");
  // }

  return orderBy("brandName", "asc");
}

/**
 * filter only specific status
 * @param {string} status
 * @return {any}
 */
function _filterOutStatus(status) {
  return [
    // ...(status === STATUSES.PENDING ? [
    //   where("emailVerified", "==", true),
    //   where("phoneNumberVerified", "==", true),
    // ] : []),
    where("status", "in", [
      STATUSES_DB_VALUES[status],
      ...(status === STATUSES.VERIFIED ? ["disabled"] : []),
    ]),
  ];
}

/**
 * get vendor by id
 *
 * @param {string} vendorUID
 * @param {Array<string>} subCollections
 */
export async function getVendorSurveyResults(vendorUID) {
  const snapshot = await getDocs(
    query(
      collectionGroup(
        db,
        "QuestionAnswersVendors",
      ),
      where("vendorId", "==", vendorUID),
      orderBy("createdAt", "desc"),
    ),
  );

  return snapshot.docs.map((s) => ({
    ...s.data(),
    uid: s.id,
  }));
}

/**
 * get vendor by id
 *
 * @param {string} vendorUID
 * @param {Array<string>} subCollections
 */
export async function updateOwner(update, vuid, uid) {
  const ref = doc(db, "Vendors", vuid, "VendorOwners", uid);
  return updateDoc(ref, update);
}


/**
 * @param {string} stateId
 * @param {string} zipCode
 * @param {string} cityName
 */
export async function checkZipCode({ stateId, cityName, zipCode }) {
  if (!stateId || !cityName || !zipCode) {
    return false;
  }
  const stateCodeData =await getStateData(stateId)
  const stateCode = stateCodeData?.abbreviation || stateCodeData?.shortName
  const r = await client.post(
    `/open/zipcode/verify`,
    { stateCode, cityName, zipCode },
  );
  return r?.data?.valid;
}



/**
 * updateVendorCategoryQuestionsAnswers
 *
 * @param {string} vendorUID

 */
export async function addVendorCategoryQuestionsAnswers(data, vuid) {
  console.log(data, vuid);
  console.log(Object.keys(data));
  const docRef = doc(db, "Vendors", vuid);
  return setDoc(docRef, data, { merge: true });
}

export async function fetchAnswers(vendorId) {
  const docRef = doc(db, "Vendors", vendorId);
  try {
    const doc = await getDoc(docRef);
    if (doc.exists()) {
      const data = doc.data();
      return data;
    } else {
      console.log("Document does not exist");
      return null;
    }
  } catch (error) {
    console.error("Error fetching document:", error);
    throw error;
  }
}