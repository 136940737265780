import {React} from "react";
import PermissionDenied from "../pages/PermissionDenied";
import {usePermissions} from "../utils";

/**
 * Route to deny/allow entry based on permission
 * @param {*} param0
 * @return {any}
 */
export default function PermissionComponent({element, permission}) {
  const [has] = usePermissions();
  let Element = element;

  if (permission && !has(permission)) {
    Element = PermissionDenied;
  }

  return (<Element />);
}
