import { client } from "../api";
import { AppError } from "./AppError";
import { auth, db } from "./firebase";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";



export const GetCurrentUserToken = () =>
  Promise.resolve(auth.currentUser?.getIdToken());


export const getCurrentUserId = () =>
  Promise.resolve(auth?.currentUser?.uid);

export const GetCurrentUserEmail = () =>
  Promise.resolve(auth.currentUser.email);

export const GetCurrentUserPhone = () =>
  Promise.resolve(auth.currentUser.phoneNumber);

export const GetCurrentUserName = () =>
  Promise.resolve(auth.currentUser.displayName);

/**
* date filter for fetching vendors
* @param {string} userType
* @param {uuid} userId
* @param {uuid} playerId
* @return {Promise<Array<any>>}
*/
export async function addplayerId(userType, userId, playerId) {
  return client.post(
    "/notification/playerid",
    { userType: userType, userid: userId, playerid: playerId },
  ).catch((e) => {
    throw new AppError(
      AppError.ADD_PLAYERID_FAILED,
      e?.response?.data?.body?.message || e.message);
  });
}

/**
* date filter for fetching vendors
* @param {string} userType
* @param {uuid} userId
* @param {uuid} playerId
* @return {Promise<Array<any>>}
*/
export async function deletePlayerID(userType, userId, playerId) {
  client.post(
    "/notification/playerid/delete",
    { data: { userType: userType, userid: userId, playerid: playerId } },
  ).catch((e) => {
    throw new AppError(
      AppError.DELETE_PLAYERID_FAILED,
      e?.response?.data?.body?.message || e.message);
  });
}

/**
* send push notification to user
* @param {string} userType
* @param {Array<string>} users
* @param {{}} options
* @return {Promise<*>}
*/
export async function sendPushNotification(userType, users, options) {
  client.post(
    "/notification/notification",
    { userType, users, options },
  ).catch((e) => {
    throw new AppError(
      AppError.PUSH_NOTIFICATION_FAILED,
      e?.response?.data?.body?.message || e.message);
  });
}



export async function getNotifications(adminId) {
  const snapshot = await getDocs(
    query(
      collection(db, "Notifications"),
      where("receiverType", "==", "admin"),
      where("receiverId", "==", adminId),
      orderBy("createdAt", "desc")
    ),
  ).catch((e) => {
    console.log(e);
    throw new AppError(
      AppError.DATA_FETCH_FAILED,
      "Failed to fetch notifications.",
    );
  });

  const uniqueContents = new Set();
  const uniqueNotifications = [];

  snapshot.docs.forEach((doc) => {
    const data = doc.data();
    const content = data.content;

    if (!uniqueContents.has(content)) {
      uniqueContents.add(content);
      uniqueNotifications.push({ uid: doc.id, ...data });
    }
  });

  return uniqueNotifications;
}

/**
 * enum for various platform
 */
export const Platform = {
  PWA: "pwa", // B&G
  ADMIN: "admin",
  VENDOR: "vendor"
}