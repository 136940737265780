/* eslint-disable */
import { Popover, Button } from "antd";
import { useAsyncTask } from "../../utils";
import { React, useEffect } from "react";
import {
    getNotifications
} from "../../services/notification";
import NotificationItem from "./NotificationItem";
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";

const Notifications = () => {
    const nav = useNavigate();
    const user = useSelector(state => state.user.user);
    const [getNotificationsForAdmin, { data }] = useAsyncTask(() => { return getNotifications(user?.uid) });
    useEffect(() => {
        getNotificationsForAdmin();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const navigatePage=(launchUrl)=>
    {
        nav(launchUrl)
    }
    return (
        <>
            <div className="notification-wrp">
                <Popover
                    className="notificationPop"
                    overlayClassName={"notificationDropdown"}
                    trigger="click"
                    placement="bottomLeft"
                    content={
                        <ul className="list-unstyled">
                            {(data || []).map((notification) => (
                                <NotificationItem notification={notification} key={notification.id}  onclick={() => navigatePage(notification.launchUrl)}/>
                            ))}
                        </ul>
                    }
                    getPopupContainer={trigger => trigger.parentElement}
                >
                    <Button>
                        <img alt="notification" src="/images/bellicon.svg"></img>
                        <span className="notificationCount"></span>
                    </Button>
                </Popover>

            </div >
        </>
    );
};

export default Notifications;
