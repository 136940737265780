import dayjs from "../utils/day";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { docsWithUid } from "../utils";
import { AppError } from "./AppError";
import { db } from "./firebase";

/**
 * get my events of a main event
 * @param {string} mainEventUID
 */
export async function getMyEvents(mainEventUID) {
  const snapshot = await getDocs(
    collection(db, "MainEvents", mainEventUID, "MyEvents"),
    orderBy("eventDate", "desc")
  );

  return snapshot.docs.map((d) => ({ ...d.data(), uid: d.id }));
}



// get vendor category
export async function getMyvendorCategory(mainEventUID) {
  const snapshot = await getDocs(
    collection(db, "MainEvents", mainEventUID, "MyVendorCategory")
  );

  return snapshot.docs.map((d) => ({ ...d.data(), vendorcategoryUID: d.id }));
}

// get vendor quiz response

export async function getQuizzResponses(mainEventUID, categoryID) {
  const snapshot = await getDocs(
    collection(db, 'MainEvents', mainEventUID, 'MyVendorCategory', categoryID, 'QuizzResponses')
  );

  return snapshot.docs.map((d) => ({ ...d.data(), quizeventUID: d.id }));
}



/**
 * get main event
 * @param {string} eventUID
 * @return {any}
 */
export async function getMainEvent(eventUID) {
  const user = await getDoc(
    doc(db, "MainEvents", eventUID),
  );

  return user.data();
}

/**
 * 
 * @param {"free"|"paid"} planType
 * @returns 
 */
export async function getSubscribedUsersBySubscriptionType(planType = "paid") {
  const snapshot = await getDocs(
    query(
      collection(db, "MainEvents"),
      where("deleted", "in", [false, '']),
      where("subscription.plan.planType", "==", planType),
      orderBy("createdAt", "desc")
    ),
  )
    .catch((e) => {
      throw new AppError(
        AppError.DATA_FETCH_FAILED,
        `Failed to fetch ${planType} subscribed users.`,
        e
      );
    });

  return docsWithUid(snapshot.docs);
}

export function getFreePlanUsers() {
  return getSubscribedUsersBySubscriptionType("free");
}

export function getSubscribedUsers() {
  return getSubscribedUsersBySubscriptionType("paid");
}

export async function getNonActiveUsers() {
  const users = await getSubscribedUsers();
  return users.filter((r) => {
    let subDate = r?.subscription?.lastUpdatedOn?.toDate();
    return subDate >= dayjs(subDate).add(3, "months")
  });
}

export async function getBudgetSplitTotalValue(eventUID) {
  console.log("eventUID",eventUID);
  const subcollectionRef = collection(db, 'MainEvents', eventUID, 'BudgetSplit');

  // Retrieve documents from the subcollection
  const subcollectionSnapshot = await getDocs(subcollectionRef);

 // Sum up the 'value' field from all subcollection documents
 const totalValue = subcollectionSnapshot.docs.reduce((acc, doc) => {
  const data = doc.data();
  const value = data.value || 0; // Assume default value is 0 if 'value' is undefined
  return acc + value;
}, 0);

return totalValue;
}

export async function getSumInvoiceAmount(mainEventId) {
  const contractsRef = collection(db, 'Contracts');

  // Create a query to filter based on mainEventId and status=completed
  const queryFilter = query(
    contractsRef,
    where('mainEventId', '==', mainEventId),
    where('status', '==', 'completed')
  );

  // Retrieve documents based on the query
  const contractsSnapshot = await getDocs(queryFilter);

  // Sum up the 'invoiceAmount' field from the filtered documents
  const totalInvoiceAmount = contractsSnapshot.docs.reduce((acc, doc) => {
    const data = doc.data();
    const invoiceAmount = data.invoiceAmount || 0; // Assume default value is 0 if 'invoiceAmount' is undefined
    return acc + invoiceAmount;
  }, 0);

  return totalInvoiceAmount;
}