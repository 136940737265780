import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import {AppError} from "./AppError";
import {auth, db} from "./firebase";

/**
 * map firestore contract status to listing statuses
 */
const contractStatusMap = {
  "pending": "draft",
  "active": "signed",
  "completed": "completed",
  "disputed": "disputed",
  "cancelled": "canceled",
  "uploaded": "completed"
}


/**
 * get vendor contracts by uid
 * @param {sring} vendorUID
 * @param {string} status
 * @param {boolean} contractState
 * @return {Promise<Array<any>>}
 */
export async function vendorContracts(vendorUID, status, contractState = "contract") {
  let clauses = [];

  if (contractState === "contract") {
    vendorContractClauses(status, clauses);
  }

  const snapshot = await getDocs(
    query(
      collection(db, "Contracts"),
      where("vendorId", "==", vendorUID),
      where("contractState", "==", contractState),
      ...clauses,
      orderBy("createdAt", "desc"),
      where("status", "==", contractStatusMap[status] || status),
    ),
  )
    .catch((e) => {
      throw new AppError(
        AppError.DATA_FETCH_FAILED,
        "Failed to fetch data.",
        e,
      );
    });

  return snapshot.docs.map((s) => ({
    ...s.data(),
    uid: s.id,
  }));
}

/**
 * generate query clauses based on status
 * 
 * @param {string} status
 * @param {any[]} clauses
 */
export function vendorContractClauses(status, clauses) {
  if (status === "uploaded") {
    clauses.push(
      where("contractType", "==", "selfManaged")
    );
  }
}

/**
 * get vendor contracts by uid
 * @param {sring} contractUID
 * @param {string} status
 */
export async function cancelContract(contractUID) {
  const ref = doc(db, "Contracts", contractUID);

  return updateDoc(ref, {
    cancelledAt: serverTimestamp(),
    cancelledBy: auth.currentUser.email,
    status: "cancelled",
  })
    .catch((e) => {
      throw new AppError(
        AppError.DATA_UPDATE_FAILED,
        `Failed to cancel the contract ${contractUID}`,
        e,
      );
    });
}

/**
 * get vendor contracts by uid
 * @param {sring} contractUID
 * @param {string} status
 */
export async function getContract(contractUID) {
  const s = await getDoc(
    doc(db, "Contracts", contractUID),
  )
    .catch((e) => {
      throw new AppError(
        AppError.DATA_FETCH_FAILED,
        "Failed to fetch contract.",
        e,
      );
    });

  return {uid: s.uid, ...s.data()};
}
