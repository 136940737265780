import React from "react";
import {Route} from "react-router-dom";

export const routes = [];

export const publicRoutes = () => {
  return (
    <React.Fragment>
      {routes.map((route, i) => {
        return (
          <Route key={i} path={route.path} element={<route.component />} />
        );
      })}
    </React.Fragment>
  );
};
